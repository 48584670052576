var validator = require('ui-validator');
var v = validator.validators;

var digitsOnly = validator.createValidator(function(options, value, key, attributes) {
  var val = value.replace(/\D/g, ''); // strip out all non-digit chars
  if (val !== value) {
    // at least one character is not a digit
    return 'Enter a valid security code';
  }
});

module.exports = {
  code: {
    required: v.required({message: 'Enter a valid security code'}),
    inputLength: v.inputLength({min: 6, max: 6, message: 'Enter a valid security code'}),
    // digitsOnly instead of v.isNumber, because we don't want to allow periods/commas
    digitsOnly: digitsOnly()
  },
  trust: {}
};

