const $ = require('lc-jquery');

const passwordFields = $('[type=password]');

passwordFields.wrap('<div class="password-container-eye-icon"/>');

const passwordContainers = passwordFields.parent();

passwordContainers.append($('<button type="button" class="show-password-button">'));

passwordFields.addClass('password-field');

const passwordButtons = passwordContainers.find('.show-password-button');

const switchPasswordVisibility = (event) => {
    event.preventDefault();
    const target = $(event.target);
    if (target.parent().find('input').attr('type') === 'password') {
        target.parent().find('input').attr('type', 'text');
    } else if (target.parent().find('input').attr('type') === 'text') {
        target.parent().find('input').attr('type', 'password');
    }
};

passwordButtons.click(switchPasswordVisibility);

