import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import randomID from '../../utils/createRandomId';

import TableRow from './components/table-row/table-row';

/** DataTable is a component for quickly creating a table that can have headings and N number of row */
const DataTable = ({ headings, rows, theme }) => {
  const renderHeadings = () => {
    if (headings) {
      return (
        <thead className="DataTable__head">
          <TableRow key={randomID('th')} rowData={headings} isHeading={true} />
        </thead>
      );
    }
  };

  const renderTableBody = () => {
    return (
      <tbody className="DataTable__body">
        {rows.map((row, rowIndex) => (
          <TableRow key={randomID(`tr-${rowIndex}`)} rowData={row} />
        ))}
      </tbody>
    );
  };

  return (
    <table
      className={classNames('DataTable', { [`DataTable--${theme}`]: !!theme })}
    >
      {renderHeadings()}
      {renderTableBody()}
    </table>
  );
};

DataTable.defaultProps = {
  rows: [],
  theme: 'default'
};

DataTable.propTypes = {
  /** Headings of the table used in table head*/
  headings: PropTypes.array,
  /** Rows of the table used in table body*/
  rows: PropTypes.array,
  /** Theme to apply to the component */
  theme: PropTypes.oneOf(['default'])
};

export default DataTable;

require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/components/data-table/data-table.scss');