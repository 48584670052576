/* eslint-disable */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useSessionData } from '../../providers/session-provider/session-provider';

import HeaderMobile from './components/header-mobile';
import HeaderDesktop from './components/header-desktop-wsn';

import objectToQueryString from '../../../shared_modules/utils/objectToQueryString';
import http from 'lc-http';


/**
 * A component to show a header switching between:
 *  - HeaderDesktop: If viewport is greater or equal to 900px width
 *  - HeaderMobile: If viewport is less than 900px width
 * */
const Header = ({ postUrl, ctaProps, showCtaUndocked, secondaryLinks }) => {
  const { isLoggedIn } = useSessionData();
  const [navProps, setNavProps] = useState({});

  useEffect(() => {}, []);

  useEffect(() => {
    http.post(postUrl, objectToQueryString({ fragment: 'header-menu-nav-new', prefix: 'header-' })).then((res) => {
      setNavProps(res.data.items);
    }).catch((e) => {});
  }, [postUrl]);

  return (
    <header className='header'>
      <div className='header_container_mobile'>
      <HeaderMobile
        navProps={navProps}
        secondaryLinks={secondaryLinks}
        isLoggedIn={isLoggedIn}
        showCtaUndocked={showCtaUndocked}
        ctaProps={ctaProps}
        showMenuLinks={false}
      />
      </div>
      <div className='header_container_desktop'>
      <HeaderDesktop
        navProps={navProps}
        secondaryLinks={secondaryLinks}
        isLoggedIn={isLoggedIn}
        showCtaUndocked={showCtaUndocked}
        ctaProps={ctaProps}
      />
      </div>
    </header>
  );
};

Header.defaultProps = {
  showCtaUndocked: false,
  secondaryLinks: [],
  postUrl: '/auth/menu-nav'
};

Header.propTypes = {

  /**
   * The text for the call to action button showed in the desktop secondary nav
   * If is not provided cta button will not be showed. If neither any secondaryLink is provided, Nav bar will no be showed
  */
  ctaText: PropTypes.string,
  /** The URL to redirect after click CTA button */
  ctaUrl: PropTypes.string,
  postUrl: PropTypes.string,
  /**
   * CTA button is showed only when primary nav is hidden, but if showCtaUndocked is true,
   * it will be showed always (It will be hidden if no ctaText is provided).
  */
  showCtaUndocked: PropTypes.bool,
  secondaryLinks: PropTypes.array
};

export default Header;

require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/components/global-header/global-header.scss');
require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/components/global-header/images/back-button.svg');