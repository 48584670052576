import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const InteractionCtx = createContext(null);

const ManageInteractionProvider = ({ children }) => {
  const [interaction, setInteraction] = useState(false);

  const onInteraction = value => {
    setInteraction(value);
  };

  return (
    <InteractionCtx.Provider value={{ onInteraction, interaction }}>
      {children}
    </InteractionCtx.Provider>
  );
};


ManageInteractionProvider.propTypes = {
  children: PropTypes.object.isRequired
};

export default ManageInteractionProvider;
export const useInteraction = () => useContext(InteractionCtx);

