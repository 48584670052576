var f = require('ui-formatter').formatters;
var $ = require('lc-jquery');

// there is tension between 'formatting' and 'constraint validation' and we're punting here on making toDigits a 'real' formatter.
// let's keep ui-formatter clean and for 'real formatting' for now before deciding on patterns to adhere to

function toDigits(value, key, attributes) {
  return value.replace(/[^\d]/g, '');
}

/* ignored lines for istanbul as $.proxy won't allow to change thiese values */
function toMoneyRestricted(value, key, attributes) {
  var restrictedValue = value;
  /* istanbul ignore else */
  if (this.allowedDigits) {
    var decSplit = value.split('.');
    var digits = toDigits(decSplit[0]);
    if (digits.length > this.allowedDigits) {
      decSplit[0] = digits.substring(0, this.allowedDigits);
      restrictedValue = decSplit.join('.');
    }
  }
  /* istanbul ignore else */
  if (this.currency === '$') {
    // temporary! if we are specifying currency translate the value back into a number
    // this is here untill uiMoney fixes the problem described in LMS-70409
    // TODO: CHECK UP ON THIS. 2016/08/08, BS
    restrictedValue = f.toNumber(restrictedValue);
  }

  var toMoney = $.proxy(f.toMoney, this);
  return toMoney(restrictedValue, key, attributes);
}

module.exports = {
  loanAmount: {
    formatter: $.proxy(toMoneyRestricted, {allowedDigits: 5, currency: '$', formatString: '¤ #'}),
    unformatter: f.toNumber
  }
};

