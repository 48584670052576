'use strict';
require('../ui-experience-system'); // for images
require('../ui-experience-system/core'); // loads fonts
require('../xd-prequal-modal');
require('../propulsion-site-header');
require('../propulsion-site-footer');
var $ = require('lc-jquery');

var $docEl = $(document.documentElement);
// adds ie classes to root element
require('../ie-marker')($docEl);

/* global DocumentTouch */ // for the eslinter
var isTouch = (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch); // borrowed from modernizr, but false positives

// AUTO-FOCUS FIELD
// Focus if not touch device and not IE11 or below (which hides the placeholder text on focus)
// and if not iframed (to prevent stealing focus when previewing in iframes)
/* istanbul ignore if */
if (!$docEl.hasClass('lte-ie11') && !isTouch && window === window.top) {
  $(document).scrollTop(0);
  $('input[data-autofocus]').focus();
}

module.exports = {};

require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/base-layout-propulsion/base-layout.scss');