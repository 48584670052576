import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const HeaderLogo = ({ className, linkTo, theme }) => {
  return (
    <div className={classNames('GlobalHeaderLogo', className)}>
      <a className="GlobalHeaderLogo__link" href={linkTo}>
        <div className={`GlobalHeaderLogo__img--${theme}`} aria-label="LendingClub Home Page" role="img" />
      </a>
    </div>
  );
};

HeaderLogo.defaultProps = {
  linkTo: '/'
};

HeaderLogo.propTypes = {
  className: PropTypes.string,
  linkTo: PropTypes.string,
  theme: PropTypes.oneOf(['white', 'blue']).isRequired
};

export default HeaderLogo;

require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/components/global-header/components/header-logo/header-logo.scss');
require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/components/global-header/components/header-logo/images/lendingclub-logo-white.svg');
require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/components/global-header/components/header-logo/images/lendingclub-logo.svg');