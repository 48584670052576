import validation from '../../../../../shared_modules/utils/fnValidations';

const getListItems = arr => {
  const items = arr
    .filter(obj => validation.isObject(obj))
    .map(tag => tag.props.children.toString());

  return makeList(items);
};

const makeList = list => {
  const uList = list
    .map(list => {
      return `<li>${list}</li>`;
    })
    .join('');

  return `<ul>${uList}</ul>`;
};

export const isATableNode = node => node.name === 'table';

const getRows = rows => {
  const tableRows = rows.map(row =>
    row.map(node => {
      if (
        node &&
        typeof node === 'object' &&
        node[0].type === undefined &&
        node.length === 1
      ) {
        return node.toString();
      }
      if (node[0].type) {
        return node[0].props.children.toString();
      }
      if (node) {
        return node;
      }
    })
  );

  return getTags(tableRows);
};

const getTags = tags => {
  const tableTags = tags.map(tag =>
    tag.map(children => {
      if (validation.isString(children)) {
        return children;
      }
      return getListItems(children);
    })
  );

  return tableTags;
};

export const getTableData = node => {
  const cmsData = [];
  const tables = node
    .filter(table => validation.isObject(table))
    .map(result => result.props.children);
  const rows = tables[0]
    .filter(row => validation.isObject(row))
    .map(row => row.props.children)
    .map(cells => cells.filter(cell => cell.type))
    .map(content => content.map(child => child.props.children));
  const rowsData = rows.map(row => [].concat(...row));

  rowsData.forEach((row, index) => {
    row.forEach(cell => {
      if (!cmsData[index]) {
        cmsData[index] = [];
      }

      if (validation.isString(cell)) {
        cmsData[index].push(cell);
      }

      if (validation.isObject(cell)) {
        let content = cell.props.children;
        if (content) {
          cmsData[index].push(content);
        }
      }
    });
  });
  return getRows(cmsData);
};

