import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * Component that show the text and the icon hamburguer
 * for the mobile nav with the correct status when is open and close.
 */
function HamburguerButton({ classname, toggleCallback }) {
  const [isOpen, toggleOpen] = useState(false);
  const statusIsOpen = { status: 'close' };
  const statusIsClose = { status: 'open' };
  const { status } = isOpen ? statusIsOpen : statusIsClose;
  const toggleState = () => {
    toggleOpen(isOpenState => !isOpenState);
    toggleCallback(isOpen);
  };
  return (
    <div
      className={classNames('GlobalHamburguerButton__container', {
        [`GlobalHamburguerButton__container--${classname}`]: classname
      })}
      onClick={toggleState}
    >
      <div className={`GlobalHamburguerButton__icon-${status}`} />
    </div>
  );
}

HamburguerButton.propTypes = {
  /** Function Function callback
   * that it's called when the componentis clicked,
   * this function receives an argument `isOpen`
   * wich is the status of the component if it's open or close. */
  toggleCallback: PropTypes.func,
  /** String to add a CSS class */
  classname: PropTypes.string
};

export default HamburguerButton;

require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/components/global-header/components/header-mobile/components/hamburguer-button.scss');
require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/components/global-header/components/header-mobile/components/images/closed.png');
require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/components/global-header/components/header-mobile/components/images/hamburgermenu.png');