'use strict';

var $ = require('lc-jquery');
var Prom = require('es6-promise-polyfill').Promise;
var FontFaceObserver = require('fontfaceobserver/fontfaceobserver.js');

function FontLoader() {
  this.doc = $(document.documentElement);
  this.fontNames = {
    regular: 'Haas Grotesk Regular',
    bold: 'Haas Grotesk Bold'
  };
  this.fontClass = 'fonts-loaded';

  // skip loading the font if HBS template indicates
  // that the font is already loaded via the fontClass
  if (!this.hasFont()) {
    this.loadFonts();
  }
}

FontLoader.prototype.hasFont = function hasFont() {
  return this.doc.hasClass(this.fontClass);
};

FontLoader.prototype.loadFonts = function loadFonts() {
  // use the non-standalone version of fontfaceobserver,
  // the one with a Promise polyfill for IE9-11.
  // @font-face definition in ui-experience-system
  // Using technique in https://www.filamentgroup.com/lab/font-events.html
  // $base-font-family (system fonts) gets applied to body first, then $webfont-font-family when
  // web fonts are loaded via the fontfaceobserver API and a class is applied to the document element.
  // this loader loads it async and prevents invisible content by adding a class to the
  // document element to trigger font replacement when the font is available

  var fontRegular = new FontFaceObserver(this.fontNames.regular);
  var fontBold = new FontFaceObserver(this.fontNames.bold);
  var self = this;

  Prom.all([
    fontRegular.load(),
    fontBold.load()
  ]).then(function promiseThen() {
    self.doc.addClass(self.fontClass);
  });
};


module.exports = FontLoader;

