import React from 'react';
import PropTypes from 'prop-types';
import { App as PropulsionApp } from '@ui-propulsion/react-components';

import BaseProviders from '../../providers/base-providers/base-providers';
import GlobalHeader from '../global-header/global-header';

const GloablHeaderApp = ({ providers, ctaProps, secondaryLinks }) => {
  return (
    <PropulsionApp>
      <BaseProviders {...providers}>
        <GlobalHeader ctaProps={ctaProps} secondaryLinks={secondaryLinks}/>
      </BaseProviders>
    </PropulsionApp>
  );
};

GloablHeaderApp.propTypes = {
  providers: PropTypes.object,
  ctaProps: PropTypes.array,
  secondaryLinks: PropTypes.array
};

export default GloablHeaderApp;

