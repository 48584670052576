import React from 'react';
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';
import FootnoteSup, { isAFootnoteNode } from './wrappers/footnote-sup';
import { isATableNode, getTableData } from './wrappers/data-table/data-table';
import Svg, { isAnSvgNode } from './wrappers/svg';
import DataTable from '../data-table/data-table';
import LinkAction, { isALinkAction } from './wrappers/link-actions/link-actions';

const transformChildren = (children) => {
  if (!Array.isArray(children)) {
    return null;
  }
  return children.map((node, index) => convertNodeToElement(node, index, transform));
};

const transform = (node, index) => {
  if (isATableNode(node)) {
    const children = transformChildren(node.children);
    const rows = getTableData(children);
    const headings = rows.shift();

    return <DataTable key={index} headings={headings} rows={rows}></DataTable>;
  }

  if (isAFootnoteNode(node)) {
    return <FootnoteSup key={index} id={node.attribs['data-footnote']} > {transformChildren(node.children)} </FootnoteSup>;
  }

  if (isAnSvgNode(node)) {
    return <Svg key={index} attributes={node.attribs} > {transformChildren(node.children)} </Svg>;
  }

  if (isALinkAction(node)) {
    return <LinkAction key={index} href={node.attribs.href}> {transformChildren(node.children)} </LinkAction>;
  }
};

const CMSParser = (html) => {
  return ReactHtmlParser(html, { transform });
};

export default CMSParser;

