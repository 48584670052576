var $ = require('lc-jquery');
var each = require('lodash.foreach');
var Emitter = require('events').EventEmitter;
var dynamicModuleRegistry = require('dynamic-module-registry');
var FieldManager = require('./field-manager');
var inherits = require('inherits');
var FormValidator = require('ui-form-validator');
var Spinner = require('../../xd-spinner');
var NotificationBox = require('../../xd-notification-box');
var suppressNotifications = dynamicModuleRegistry.get('suppressNotifications');

// xD-TODO: this should pull from ui-experience-system but cannot.
// to do so, we need ui-es in deps and not dev-deps, but then we get dupicate
// css as well as its assumptions about having a secondary nav. can be done
// once we split ui-es into separate components. until then, copied file.
var scrollToElement = require('./scroll-to-element');
var isInViewport = require('./is-in-viewport');

/**
 * Represents a Form Manager.
 * @constructor
 * @classdesc Form manager helps handle the typical uses cases of your forms: formatting, validation, helper text, ajax/standard submission, and display of a loading spinner/throbber.
 * @param {Object|String} container - A jQuery object, direct DOM reference, or selector string
 * @param {Object} [options] - A set of options used to configure the form manager. Options listed alphabetically below.
 * @param {Boolean} [options.allowTracking] - Default `true`. Will track validation errors with lcTracking. Set to `false` to disable.
 * @param {Object} [options.endPoint] - An object containing the configuration for the form's endpoint.
 * @param {String} [options.endPoint.contentType] - Used by ajax request. Not used if `options.endPoint.nativeSubmit` is `true`. See http://api.jquery.com/jquery.ajax/
 * @param {String} [options.endPoint.dataType] - Default `'json'`. Used by ajax request. Not used if `options.endPoint.nativeSubmit` is `true`. See http://api.jquery.com/jquery.ajax/
 * @param {Object} [options.endPoint.handlers] - Specify custom functions to handle error and success events of ajax request. Not used if `options.endPoint.nativeSubmit` is `true`.
 * @param {Function} [options.endPoint.handlers.error] - Callback function to handle validation error(s). Will be passed `data` argument that has an `errors` key. `errors` is an array of errors. ??more argument info??
 * @param {Function} [options.endPoint.handlers.success] - Callback function to hanle validation success. Will be passed `data` argument. ??more argument info??
 * @param {String} [options.endPoint.method] - Method to submit the form by (whether standard submission or ajax). Defaults to `'POST'`. Can also be `'GET'`. If specified, will override the `method` attribute of the form's HTML.
 * @param {Boolean} [options.endPoint.nativeSubmit] - Default `false`.
 * @param {Boolean} [options.endPoint.processData] - Default `true`. Used by ajax request. Not used if `options.endPoint.nativeSubmit` is `true`. See http://api.jquery.com/jquery.ajax/
 * @param {String} [options.endPoint.url] - Url to submit the form to (whether standard submission or ajax). Default's to current URL. Use relative URL if possible, but absolute URLs should work within the constraints of CORS/CSP. If specified, will override the `action` attribute of the form's HTML
 * @param {String} [options.errorDisplayStyle] - Can be `'field'`, `'form'`, or `'none'`. Default is `'field'`. `'field'` shows errors immediately beside the form control that was invalid. `'form'` shows errors via `xd-notification-box` at the top of the page. `'none'` will validate but not show errors at all; if you use `'none'` your app must handle the styling/showing of errors on its own.
 * @param {Object} [options.schemas] - Contains the two types of schemas used by [link] FormValidator.
 * @param {Object} [options.schemas.validator] - Schema to validate the form fields. See [link] FormValidator.
 * @param {Object} [options.schemas.formatter] - Schema to format the form fields. See [link] FormValidator.
 * @param {Boolean} [options.spinner] - Default `false`. If true, when the form is submitted, a loading throbber/spinner will be displayed while waiting on a response from the server. See [link] ui-spinner.
 * @param {Object|String} [options.submitControl] - A jQuery object, direct DOM reference, or selector string pointing to the submit button of a form. Defaults to `'[type=submit]'`; If found, button will be disabled on form submission and re-enabled on form error/success.
 * @param {Boolean} [options.reenableAfterSuccess] - Default `false`. If true, form is re-enabled (hide spinner, make form fields editable, button clickable) after successful submission. Mainly for forms that don't submit (e.g., a calculator)
 * @param {Boolean} [options.validatePerField] - Default `true`, each field is validated one-by-one as the user interacts and on form submit. If false, validation is done only upon form submit.
 */
function FormManager(container, options) {
  this.setOptionDefaults(options);
  this.setContainers(container);
  this.addListeners();
}

inherits(FormManager, Emitter);

/**
 * Determines if an argument is a jQuery object
 * @function isJquery
 * @memberof FormManager
 * @instance
 * @param {Object|String} stringOrElement - A jQuery object, direct DOM reference, or selector string
 */
FormManager.prototype.isJquery = function isJquery(stringOrElement) {
  // need the instanceof check + constructor check because two diff usages of jQuery won't
  // count as the same when comparing with instanceof.
  // see: http://stackoverflow.com/questions/1853223/check-if-object-is-a-jquery-object/25004941#25004941
  return stringOrElement && (stringOrElement instanceof $ || stringOrElement.constructor.prototype.jquery);
};

/**
 * Set the container and descendant jQuery elements used by Form Manager.
 * @function setContainers
 * @memberof FormManager
 * @instance
 * @param {Object|String} container - A jQuery object, direct DOM reference, or selector string
 */
FormManager.prototype.setContainers = function setContainers(container) {
  this.$container = this.isJquery(container) ? container : $(container);
  this.$form = this.$container.find('[data-managed-form]');
  this.$nativeSubmitForm = this.$container.find('[data-hidden-form]');
  this.notificationBox = new NotificationBox(this.$form.find('[data-notification-container]'));
  this.spinner = new Spinner(this.$container.find('.form-spinner'));
  this.$submitControl = this.isJquery(this.options.submitControl) ? this.options.submitControl : this.$form.find(this.options.submitControl);
  this.fieldManager = new FieldManager(this.$form);
};

/**
 * Set some suitable defaults for any values that might be missing in configuration options
 * @function setOptionDefaults
 * @memberof FormManager
 * @instance
 * @param {Object} options - Configuration object passed into the constructor
 */
FormManager.prototype.setOptionDefaults = function setOptionDefaults(options) {
  // set up some empty objects if missing options so at least future checks have errors
  this.options = options || {};
  this.options.schemas = this.options.schemas || {};

  // by default enable tracking
  if (this.options.allowTracking === undefined) {
    this.options.allowTracking = true;
  }

  // set default for errorDisplayStyle if not set
  if (!this.options.errorDisplayStyle) {
    this.options.errorDisplayStyle = 'field';
  }

  // set default for validation trigger if not set
  if (this.options.validatePerField !== false) {
    this.options.validatePerField = true;
  }

  if (!this.options.submitControl) {
    this.options.submitControl = '[type=submit]';
  }

  // set up endPoint to avoid errors. also set default error and success handlers
  this.options.endPoint = this.options.endPoint || {};
  this.options.endPoint.handlers = this.options.endPoint.handlers || {};
  if (typeof this.options.endPoint.handlers.error !== 'function') {
    this.options.endPoint.handlers.error = $.proxy(this.handleAjax, this);
  }
  if (typeof this.options.endPoint.handlers.success !== 'function') {
    this.options.endPoint.handlers.success = $.proxy(this.handleAjax, this);
  }
};

/**
 * Add event listeners to various form elements as well as instantiating Form Validator
 * @function addListeners
 * @memberof FormManager
 * @instance
 */
FormManager.prototype.addListeners = function addListeners() {
  // register to reset all fields before instantiating formValidator below (which also listens for submit)
  this.$form.on('submit', $.proxy(this.resetStates, this));

  // register to disable button properly before instantiating formValidator below (which also listens for submit)
  this.$form.on('submit', $.proxy(this.disableForm, this));

  this.formValidator = new FormValidator(this.$form, {
    validatorSchema: this.options.schemas.validator,
    formatterSchema: this.options.schemas.formatter
  });

  if (this.options.validatePerField) {
    this.fieldManager.addFieldValidationListeners(this.formValidator);
    this.formValidator.on('errorFields', $.proxy(this.handleErrors, this));
    this.formValidator.on('successFields', $.proxy(this.fieldManager.setFieldsState, this.fieldManager, 'is-valid'));
  }
  this.formValidator.on('error', $.proxy(this.handleFormSubmitErrors, this));
  this.formValidator.on('success', $.proxy(this.handleFormSubmitSuccess, this));
};

/**
 * Disable the submit button to prevent multiple submissions of the form
 * @function disableForm
 * @memberof FormManager
 * @instance
 */
FormManager.prototype.disableForm = function disableForm() {
  // prevent ios bug where when fields are focused, position fixed
  // elements are treated as position relative to make sure forms fields
  // visible in UI
  if (window.document.activeElement) {
    window.document.activeElement.blur();
  }

  this.resetStates();
  this.$submitControl.prop('disabled', true);
};

/**
 * Reenable the submit button after it's been disabled
 * @function enableForm
 * @memberof FormManager
 * @instance
 */
FormManager.prototype.enableForm = function enableForm() {
  this.toggleSpinner('hide');
  this.$submitControl.prop('disabled', false);
};

/**
 * Reset all form fields back to their defaut state as well as remove form-level notifications
 * @function resetStates
 * @memberof FormManager
 * @instance
 */
FormManager.prototype.resetStates = function resetStates() {
  this.fieldManager.setFieldState(this.$form.find('.form-control'), null); // reset all field states
  this.setFormState(); // reset form state
};

/**
 * Set the state of the entire form via xd-notification-box. Can also be used to hide form-level state.
 * @function setFormState
 * @memberof FormManager
 * @instance
 * @param {String} newState - One of `error`, `success`, or `notice`. If left null will revert to default state.
 * @param {Array} messages - strings to display in xd-notification-box.
 */
FormManager.prototype.setFormState = function setFormState(newState, messages) {
  if (suppressNotifications) return;
  if (!newState || !this.notificationBox.isStateValid(newState) || !messages || messages.length === 0) {
    // no state/message was passed in or we can't find a match, hide the notification-box
    this.notificationBox.collapse();
    return;
  }
  // we have a matching state; show the message with that method
  this.$form.find('[data-notification-box]')
    .triggerHandler(newState, messages);
};

/**
 * Track validation errors with lcTracking.
 * @function trackErrors
 * @memberof FormManager
 * @instance
 * @param {Array} keys - Form validation error keys (field names)
 * @param {Array} messages - Form validation error messages
 */
FormManager.prototype.trackErrors = function trackErrors(keys, messages) {
  window.lcTracking.trackCustomEvent({
    eventName: 'Funnel Error',
    ERROR_KEYS: keys.join('::'),
    ERROR_MESSAGES: messages.join('::')
  });
};

/**
 * Get the `method` and `url` attributes for this form. Prefers `options` over HTML attributes over defaults
 * @function getFormAttributes
 * @memberof FormManager
 * @instance
 * @param {Object} endPoint - The configuation passed in as `options.endPoint` to the constructor
 */
FormManager.prototype.getFormAttributes = function getFormAttributes(endPoint) {
  return {
    method: endPoint.method || this.$form.attr('method') || 'POST',
    action: endPoint.url || this.$form.attr('action') || window.location.href
  };
};

/**
 * Show or hide the loading spinner/throbber (if enabled via config `options`)
 * @function toggleSpinner
 * @memberof FormManager
 * @instance
 * @param {String} method - Either 'show' or 'hide'
 */
FormManager.prototype.toggleSpinner = function toggleSpinner(method) {
  if (this.options.spinner && typeof this.spinner[method] === 'function') {
    this.spinner[method]();
  }
};

/**
 * Submit the form as a secondary hidden form (not an ajax submission). Will reload a new page via GET or POST
 * @function submitAsForm
 * @memberof FormManager
 * @instance
 * @param {Object} payload - An object containing for field names/values to be submitted to `options.endPoint.url`
 * @param {Object} endPoint - The configuation passed in as `options.endPoint` to the constructor
 */
FormManager.prototype.submitAsForm = function submitAsForm(payload, endPoint) {
  // prep the hidden form for submission
  var attrs = this.getFormAttributes(endPoint);
  this.$nativeSubmitForm.attr('action', attrs.action);
  this.$nativeSubmitForm.attr('method', attrs.method);
  if (attrs.method === 'POST') {
    // append csrf token to payload if POST
    payload.csrf = $('meta[name=csrf-token]').attr('content');
  }
  this.$nativeSubmitForm.empty(); // in case it was already submitted, remove any prior form controls

  // append validated and 'unformatted' (formatted for submit) data as input fields to the form
  var self = this;
  each(payload, function(val, key) {
    $('<input type="hidden">')
      .attr('name', key)
      .attr('value', val)
      .appendTo(self.$nativeSubmitForm);
  });

  return this.$nativeSubmitForm.submit();
};

/**
 * Submit the form via an ajax submission
 * @function submitAsAjax
 * @memberof FormManager
 * @instance
 * @param {Object} payload - An object containing for field names/values to be submitted to `options.endPoint.url`
 * @param {Object} endPoint - The configuation passed in as `options.endPoint` to the constructor
 */
FormManager.prototype.submitAsAjax = function submitAsAjax(payload, endPoint) {
  return $.ajax({
    type: endPoint.method,
    dataType: endPoint.dataType || 'json',
    contentType: endPoint.contentType,
    data: payload,
    processData: endPoint.processData,
    url: endPoint.url,
    error: $.proxy(this.options.endPoint.handlers.error, this),
    success: $.proxy(this.options.endPoint.handlers.success, this)
  });
};

/**
 * Handler for both success and error results from ajax subission via `submitAsAjax`
 * @function handleAjax
 * @memberof FormManager
 * @instance
 * @emits success
 * @param {Object} data - Service response payload
 */
FormManager.prototype.handleAjax = function handleAjax(data) {
  if (data.errors && data.errors.length > 0) {
    this.handleFormSubmitErrors(data.errors);
  } else {
    this.emit('success', data);
    if (this.options.reenableAfterSuccess) {
      this.enableForm();
    }
  }
};

/**
 * Handle the error result from validation or a failed ajax submission
 * @function handleErrors
 * @memberof FormManager
 * @instance
 * @emits validationError
 * @param {Object} errors - Form validation errors
 */
FormManager.prototype.handleErrors = function handleErrors(subsetOfFieldsValidated, errors) {
  var errorKeys = [];
  var errorMessages = [];

  var self = this;
  if (errors) {
    each(errors, function(errs, key) {
      each(errs, function(error) {
        if (error !== undefined) {
          if (self.options.errorDisplayStyle === 'field') {
            self.fieldManager.showFieldError(self.$form.find('[name=' + key + ']'), error);
          }
          errorKeys.push(key);
          errorMessages.push(error);
        }
      });
    });

    if (self.options.errorDisplayStyle === 'form') {
      self.setFormState('error', errorMessages);
    }

    if (this.options.allowTracking && window.lcTracking) {
      this.trackErrors(errorKeys, errorMessages);
    }

    // any field not marked as errors assume were correct. only do if all fields were
    // validated, not if only some were
    // TODO: need better way to do this. what about 'indeterminate' fields such as
    //   some not required and not filled out, are they 'valid' or 'none' for state?
    if (!subsetOfFieldsValidated) {
      self.$form.find('.form-control').each(function() {
        // jQuery this
        self.fieldManager.setFieldStateIfUnset(this, 'is-valid');
      });
    }
  }

  this.emit('validationError', errors);
};

/**
 * Handle the error result from submit validation or a failed ajax submission
 * @function handleFormSubmitErrors
 * @memberof FormManager
 * @instance
 * @param {Object} errors - Form validation errors
 */
FormManager.prototype.handleFormSubmitErrors = function handleFormSubmitErrors(errors) {
  this.handleErrors(/* subsetOfFieldsValidated */null, errors);
  // re-enable form for the user
  this.enableForm();
  this.scrollToFirstError();
};

/**
 * Handle success result from an form validation
 * @function handleSuccess
 * @memberof FormManager
 * @instance
 * @emits validationSuccess
 * @param {Object} payload - An object containing for field names/values to be submitted to `options.endPoint.url`
 */
FormManager.prototype.handleFormSubmitSuccess = function handleFormSubmitSuccess(payload) {
  var submitData = true;

  var preventDefault = function() {
    submitData = false;
  };

  this.emit('validationSuccess', { preventDefault: preventDefault });

  if (submitData) {
    this.toggleSpinner('show');
    var endPoint = this.options.endPoint;
    var attrs = this.getFormAttributes(endPoint);
    endPoint.method = attrs.method;
    endPoint.url = attrs.action;

    var method = endPoint.nativeSubmit ? 'submitAsForm' : 'submitAsAjax';
    return this[method](payload || {}, endPoint);
  }
};


/**
 * Scroll to first error as found in DOM. Meant to be done at form-submit time, not per-field valiation
 * @function scrollToFirstError
 * @memberof FormManager
 * @instance
 */
FormManager.prototype.scrollToFirstError = function scrollToFirstError() {
  // make sure first error is in view
  var $formControl = this.$form
    .find('.is-invalid')
    .first()
    .closest('.form-control');


  if ($formControl.length === 0) {
    // if no errors, nothing to scroll to
    return;
  }

  var offsets = {
    top: 60 // 50px for top nav + 10px padding
  };

  if (isInViewport($formControl, offsets)) {
    // already fully in viewport, no need to scroll to it
    return;
  }

  var speed = 600; // in ms, jquery's 'slow'
  scrollToElement($formControl, speed, offsets.top);
};

module.exports = FormManager;

