function detectIE() {

  var ua = window.navigator.userAgent;

  var msie = ua.indexOf('MSIE ');
  /*
    I had to ignore those line in coverage as unit tests covers all cases but
    Istanbul won't mark them as executed
  */
  /* istanbul ignore if  */
  if (msie > 0) {
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  var trident = ua.indexOf('Trident/');
  /* istanbul ignore if  */
  if (trident > 0) {
    var rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  var edge = ua.indexOf('Edge/');
  /* istanbul ignore if  */
  if (edge > 0) {

    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }

  return false;
}

module.exports = function ieMarker($rootEl) {
  // TODO: revisit to see if we can find cheap IE/edge
  var version = detectIE();
  /* istanbul ignore if  */
  if (version !== false) {
    var highestIEVersion = 13;
    var ieClassMarker = 'ie ie' + version;
    while (highestIEVersion >= version) {
      var lessThanClass = ' lte-ie' + highestIEVersion;
      ieClassMarker += lessThanClass;
      highestIEVersion--;
    }

    $rootEl.addClass(ieClassMarker);
  }
};

