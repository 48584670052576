import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useFootnotes } from '../../../../providers/footnotes-provider/footnotes-provider';

const searchContent = (visibleFootnotes, id, defaultContent) => {
  const index = visibleFootnotes.findIndex( footnoteId => footnoteId === id);
    if (index < 0) {
      return defaultContent;
    }
    return index + 1;
};

/**
 * A component to render a footnote superscript with its correct number.
 * It has to be inside a [<FootnotesProvider>](?path=/story/shared-modules-components-cmsparser--footnotesprovided)
 * in order to subscribe to it as a footnote and to get the correct number order based on all subscribed footnotes.
 */
const FootnoteSup = ({ id, children }) => {
  const { visibleFootnotes, subscribeSup, updateFootnoteId} = useFootnotes();
  const [content, setContent] = useState(children);
  const [subscription, setSubscription] = useState(null);
  const root = useRef();

  useEffect(() => {
    const subscription = subscribeSup(id, root);
    setSubscription(subscription);
  }, [id, subscribeSup]);

  useEffect(() => {
    if (!subscription) {
      return;
    }
    updateFootnoteId(subscription, id);
  }, [id, subscription, updateFootnoteId]);

  useEffect(() => {
    setContent( searchContent(visibleFootnotes, id, children) );
  }, [visibleFootnotes, id, children]);

  const renderContent = () => {
    return content;
  };

  return (
    <sup ref={root} id={id}>
      { renderContent() }
    </sup>
  );
};

FootnoteSup.defaultProps = {
  children: '*'
};

FootnoteSup.propTypes = {
  /**
   * The id of the FootnotesProvider's registered footnote it refers. Ex.
   * If the registered footnotes are:

```javascript
  {
    example: 'An example footnote',
    default: 'Another footnote'
  }
```
.

   * Using id 'example' will connect this footnote superscript with `"An example footnote"`
   */
  id: PropTypes.string.isRequired,
  /**
   * The content to show as superscript in case the id doesn't match any FootnotesProvider's registered footnote.
  */
  children: PropTypes.any
};

export default FootnoteSup;

export const isAFootnoteNode = (node) => {
  return node.name === 'sup' && node.attribs && node.attribs.hasOwnProperty('data-footnote');
};

