import React from 'react';
import PropTypes from 'prop-types';
import { App as PropulsionApp } from '@ui-propulsion/react-components';

import FooterGlobal from '../footer-global/footer-global';

const FooterGlobalApp = ({ disclaimer, copyright, symbols, extendFDIC, footnotes, cipDisclaimer1, cipDisclaimer2, cipDisclaimer3 }) => {
  const footer = {
    disclaimerProps: {
      disclaimer: disclaimer ? (disclaimer.string ? disclaimer.string : disclaimer) : '',
      cipDisclaimer1: cipDisclaimer1 ? (cipDisclaimer1.string ? cipDisclaimer1.string : cipDisclaimer1) : '',
      cipDisclaimer2: cipDisclaimer2 ? (cipDisclaimer2.string ? cipDisclaimer2.string : cipDisclaimer2) : '',
      cipDisclaimer3: cipDisclaimer3 ? (cipDisclaimer3.string ? cipDisclaimer3.string : cipDisclaimer3) : '',
      symbols: symbols ? (symbols.string ? symbols.string : symbols) : '',
      globalValues: copyright ? copyright.string : '',
      footnotes: footnotes ? footnotes : ''
    },
    extendFDIC: extendFDIC === 'true' ? true : false
  };

  return (
    <PropulsionApp>
      <FooterGlobal {...footer} />
    </PropulsionApp>
  );
};

FooterGlobalApp.propTypes = {
  disclaimer: PropTypes.any,
  copyright: PropTypes.any,
  symbols: PropTypes.string,
  extendFDIC: PropTypes.string,
  footnotes: PropTypes.any,
  cipDisclaimer1: PropTypes.string,
  cipDisclaimer2: PropTypes.string,
  cipDisclaimer3: PropTypes.string
};

export default FooterGlobalApp;

