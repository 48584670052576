import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const sortRefs = (subscribers) => {
  return subscribers.slice(0).sort(({ ref: refA}, {ref: refB}) => {
    if (!refA.current || !refB.current) {
      return 0;
    }
  });
};

const subscriberFactory = (updateFootnotes, subscribers) => (id, ref) => {
  const subscriber = {
    footnoteId: id,
    ref,
    updateFootnotes: updateFootnotes(),
    index: subscribers.length
  };
  subscribers.push(subscriber);
  updateFootnotes();
  return subscriber;
};

const getDefaultCtx = ( footnotes = {} ) => ({
  visibleFootnotes: [],
  footnotes: footnotes,
  subscribeSup: () => {}
});

const FootnotesCtx = createContext( getDefaultCtx() );

// eslint-disable-next-line max-lines-per-function
const FootnotesProvider = ({ footnotes, children }) => {
  const subscribers = [];
  const updateFootnotes = () => {
    const { visibleFootnotes } = sortRefs(subscribers).reduce(({ visibleFootnotes, ids }, { footnoteId }) => {
      if (footnotes[footnoteId] && !ids.has(footnoteId)) {
        visibleFootnotes.push(footnoteId);
        ids.add(footnoteId);
      }
      return { visibleFootnotes, ids };
    }, { visibleFootnotes: [], ids: new Set() });
    setState({ footnotes, visibleFootnotes, subscribeSup, updateFootnoteId });
  };

  const [{
    visibleFootnotes,
    subscribeSup,
    updateFootnoteId
  }, setState] = useState({
    visibleFootnotes: Object.keys(footnotes),
    subscribeSup: subscriberFactory(updateFootnotes, subscribers),
    updateFootnoteId: ({ index }, footnoteId) => {
      subscribers[index].footnoteId = footnoteId;
      updateFootnotes();
    }
  });

  useEffect(() => {
    updateFootnotes();
  }, [footnotes]);

  return (
    <FootnotesCtx.Provider value={{
      footnotes,
      visibleFootnotes,
      subscribeSup,
      updateFootnoteId
    }}>
      {children}
    </FootnotesCtx.Provider>
  );
};

FootnotesProvider.defaultProps = {
  footnotes: {}
};

FootnotesProvider.propTypes = {
  footnotes: PropTypes.object,
  children: PropTypes.any.isRequired
};

export default FootnotesProvider;
export const useFootnotes = () => useContext(FootnotesCtx);

