const FormManager = require('../../xd-form-manager');
const heapTracking = require('../heap-tracking');
const SmsAgreementModal = require('../../sms-agreement-modal/sms-agreement-modal');

const postRobot = require('post-robot');
const objectPath = require('object-path');
const dynamicModuleRegistry = require('dynamic-module-registry');
const target = dynamicModuleRegistry.get('widgetTargetURL');
const targetURL = (!target || !target.target) ? '' : target.target;

const topWindow = window.parent;

function getLastDigits(maskedNumber) {
    return maskedNumber.match(/\d+/g);
}

function setUpChannelForm(data) {
    const options = {
        schemas: {
            validator: {}
        },
        endPoint: {
            url: this.opts.channelSubmitUrl,
            dataType: 'json',
            method: 'POST',
            handlers: {
                success: this.channelHandler.bind(this),
                error: this.channelHandler.bind(this)
            }
        },
        triggers: {
            validator: 'field'
        },
        errorDisplayStyle: 'field',
        spinner: true
    };
    if (!this.channelFormManager) {
        this.channelFormManager = new FormManager(this.$container.find('[data-channel-view]'), options);
        this.channelFormManager.on('success', this.channelHandler.bind(this));
        this.channelFormManager.on('validationSuccess', () => this.hideAllNotifications());

        // enable button now that loginFormManager is ready
        var self = this;
        const smsAgreementModal = new SmsAgreementModal('[data-sms-agreement-modal]');
        this.$channelForm.find('[data-sms-agreement-link]').on('click', (event) => {
            smsAgreementModal.modal.show(event, true);
            event.preventDefault();
        });
        this.$channelForm.on('click', '[data-more-options-btn]', showMoreOptions.bind(this));
        this.$channelForm.on('click', '[data-reload-link]', function(event) {
            // entered code wrong 3+ times. have to start over with email/pass login
            // this way auth-service can tell if account is locked, login form can show errs, etc
            event.preventDefault();
            return self.reloadParentPage();
        });
    }
    this.$channelForm.find('[type=submit]').prop('disabled', false);
}

function checkFirstFactor() {
    this.$channelForm.find('[data-factors] input:radio:first').attr('checked', true);
}


function createFactorElement(factor, container, visible) {
    const phoneDetails = factor.phoneDetails;
    let divId;
    let inputId;
    let inputValue;
    let inputLabel;

    const lastPhoneDigits = phoneDetails && getLastDigits(phoneDetails.phoneNumber) || getLastDigits(objectPath.get(factor, 'profile.phone_number', ''));
    const email = objectPath.get(factor, 'profile.email', null);

    if (factor.type === 'OTP_SMS') {
        inputLabel = `Send text message to phone number ending in <span data-masked-phone>${lastPhoneDigits}</span>`;
    }

    if (factor.type === 'OTP_VOICE') {
        inputLabel = `Call to phone number ending in <span data-masked-phone>${lastPhoneDigits}</span>`;
    }

    if (factor.type === 'OTP_EMAIL') {
        inputLabel = `Email to <span data-masked-email>${email}</span>`;
    }

    if (phoneDetails) {
        divId = `factor-${factor.id}-${phoneDetails.id}-${factor.type}-container`;
        inputId = `factor-${factor.id}-${phoneDetails.id}-${factor.type}`;
        inputValue = `${factor.id}/${phoneDetails.id}`;
    } else {
        divId = `factor-${factor.id}-container`;
        inputId = `factor-${factor.id}-${factor.type}`;
        inputValue = `${factor.id}`;
    }

    if (!document.querySelector(`#${divId}`)) {
        const factorDiv = document.createElement('div');
        factorDiv.classList.add('factor-selection');
        factorDiv.classList.add('auth-text');
        if (!visible) {
            factorDiv.classList.add('hidden');
        }
        factorDiv.id = divId;

        const factorInput = document.createElement('input');
        factorInput.id = inputId;
        factorInput.name = 'factorId';
        factorInput.type = 'radio';
        factorInput.value = inputValue;

        const factorLabel = document.createElement('label');
        factorLabel.setAttribute('for', inputId);
        factorLabel.innerHTML = inputLabel;

        factorDiv.appendChild(factorInput);
        factorDiv.appendChild(factorLabel);

        container.append(factorDiv);
    }
}

function showMoreOptions(event) {
    event.preventDefault();
    if (event.target.attributes['data-more-options-btn'].value === 'true') {
        this.$channelForm.find('[data-factors] div').removeClass('hidden');
        this.$channelForm.find('[data-more-options-btn]').html('Show less verification options');
        this.$channelForm.find('[data-more-options-btn]').attr('data-more-options-btn', 'false');
    } else {
        this.$channelForm.find('[data-factors] div').slice(3).addClass('hidden');
        this.$channelForm.find('[data-more-options-btn]').html('Show more verification methods');
        this.$channelForm.find('[data-more-options-btn]').attr('data-more-options-btn', 'true');
    }
}

function showChannelSection(response) {
    const data = this.formatData(response);
    this.factors = data;
    let phoneFactor = {};
    let smsFactor = {};
    let emailFactor = {};
    data.forEach((factor) => {
        if (factor.type === 'OTP_VOICE' && factor.status === 'ACTIVE') {
            phoneFactor = factor;
        }
        if (factor.type === 'OTP_SMS' && factor.status === 'ACTIVE') {
            smsFactor = factor;
        }
        if (factor.type === 'OTP_EMAIL' && factor.status === 'ACTIVE') {
            emailFactor = factor;
        }
    });

    const factorsOrder = [];
    // Set data to UI
    const userHasAdditionalPhones = data.some((factor) => factor.type === 'OTP_SMS' && factor.profile.all_phones);

    if (smsFactor && smsFactor.id) {
        const allPhones = smsFactor.profile.all_phones;
        if (allPhones) {
            allPhones.forEach((phoneDetails) => {
                factorsOrder.push({ ...smsFactor, phoneDetails });
            });
        } else {
            factorsOrder.push(smsFactor);
        }
    }

    if (phoneFactor && phoneFactor.id) {
        const voicePhones = phoneFactor.profile.all_phones;
        if (voicePhones) {
            voicePhones.forEach((phoneDetails) => {
                factorsOrder.push({...phoneFactor, phoneDetails});
            });
        } else {
            factorsOrder.push(phoneFactor);
        }
    }

    if (userHasAdditionalPhones) {
        if (emailFactor && emailFactor.id) {
            factorsOrder.splice(2, 0, emailFactor);
        }
    } else {
        if (emailFactor && emailFactor.id) {
            factorsOrder.splice(0, 0, emailFactor);
        }
    }

    factorsOrder.forEach((factor, index) => {
        createFactorElement(factor, this.$channelForm.find('[data-factors]'), index <= 2);
    });
    if (factorsOrder.length > 3) {
        this.$channelForm.find('[data-more-options-btn]').removeClass('hidden');
    }
    checkFirstFactor.bind(this)();

    // setup form
    this.setUpChannelForm(data);

    // switch views
    this.$container.find('[data-login-view]').addClass('hidden');
    this.$container.find('[data-code-view]').addClass('hidden');
    this.$container.find('[data-channel-view]').removeClass('hidden');

    // track widget code section load
    this.$channelForm.find('[data-spinner]').addClass('hidden');
    heapTracking.addMFASelectionSectionTracking(targetURL);
    if (this.opts.isWidget) {
        postRobot.send(topWindow, 'showFactorSelectionScreen', { factors: this.factors }).then(function(event) {
            return;
        });
    }

    this.$container.find('[data-channel-view] [name=factorId]').off('click');
    this.$container.find('[data-channel-view] [name=factorId]').on('click', (e) => {
        const factor = {
            type: e.target.id,
            value: e.target.value
        };
        if (this.opts.isWidget) {
            postRobot.send(topWindow, 'userSelectFactor', { factor }).then(function(event) {
                return;
            });
        }
    });
}

function channelHandler(response) {
    const factorDetails = this.$channelForm.find('input[name=factorId]:checked').val().split('/');
    const factorId = factorDetails[0];
    const phoneId = factorDetails[1];
    const selectedFactor = {...this.factors.find(f => f.id === factorId), phone_id: phoneId};
    const data = this.formatData(response);
    if (data.status === 200) {
        this.showCodeSection({factors: this.factors, selectedFactor, ...data, factorDetails});
        return;
    }
    if (this.opts.isWidget) {
        this.channelFormManager.setFormState('error', data.message);
        postRobot.send(topWindow, 'userSelectFactorError', { factors: this.factors }).then(function(event) {
            return;
        });
    } else {
        const messageData = {
            'title': 'Error Logging In',
            'message': data.message,
            'type': 'ERROR'
        };
        this.channelFormManager.setFormState('error');
        this.showErrorMessage(messageData, this.$channelForm);
    }
    this.channelFormManager.enableForm();
}


module.exports = {
    setUpChannelForm,
    showChannelSection,
    channelHandler,
    checkFirstFactor,
    getLastDigits
};

