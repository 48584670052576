var $ = require('lc-jquery');

/*
container should only have one accordion within it.
accordion expects this structure:
  .accordion
    [data-accordion-section]
      [data-accordion-title]
      [data-accordion-contents]
    [data-accordion-section]
      [data-accordion-title]
      [data-accordion-contents]
*/
function Accordion(container) {
  this.container = $(container);

  this.options = {
    open: 'accordion-section--open',
    section: '[data-accordion-section]',
    title: '[data-accordion-title]',
    contents: '[data-accordion-contents]'
  };

  this.toggleListener = $.proxy(this.toggle, this);
  this.addEventListeners();
}

Accordion.prototype.addEventListeners = function addEventListeners() {
  this.container.find(this.options.title)
    .on('click', this.toggleListener);
};

// only used from consumer if there's a need to remove accordion functionality
Accordion.prototype.disable = function disable() {
  this.container.find(this.options.title)
    .off('click', this.toggleListener);

  // remove all open classes
  this.container.find(this.options.section).removeClass(this.options.open);

  // remove any inlined CSS
  this.container.find(this.options.contents).css('max-height', '');
};

Accordion.prototype.calculateHeight = function calculateHeight($section) {
  if ($section.hasClass(this.options.open)) {
    return 0;
  }
  var $contents = $section.find(this.options.contents);
  var height = $contents.get(0).scrollHeight + 3; // 3 is just for some small padding
  return height;
};

Accordion.prototype.setContentsHeight = function setContentsHeight($section, forceHeight) {
  var height = forceHeight !== undefined ? forceHeight : this.calculateHeight($section);
  $section.find(this.options.contents).css('max-height', height);
};

Accordion.prototype.toggle = function toggle(event) {
  var $section = $(event.target).closest(this.options.section);

  if ($section.hasClass(this.options.open)) {
    // this section was open; simply close it
    this.setContentsHeight($section, 0);
    $section.removeClass(this.options.open);
  } else {
    // check to see if another section was already open
    var $open = this.container.find('.' + this.options.open);
    if ($open.length > 0) {
      // another section is open, start closing it
      this.setContentsHeight($open, 0);
      $open.removeClass(this.options.open);
    }
    // open the active section just clicked
    this.setContentsHeight($section);
    $section.addClass(this.options.open);
  }
};

module.exports = Accordion;

require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/xd-accordion/src/images/chevron-inverse.svg');
require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/xd-accordion/src/images/chevron.svg');