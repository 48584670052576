'use strict';

var FontLoader = require('./src/font-loader');

// handle the invocati fon here so client can just require() and be done
// best practices around this?
module.exports = {
  FontLoader: new FontLoader()
};

