const isEmpty = value => !value;

const isEmail = value => value.match(/^[A-Za-z0-9._%+-]+@(?:[A-Za-z0-9-]+\.)+[A-Za-z]{2,4}$/);

const isObject = value =>
  value && typeof value === 'object' && value.constructor === Object;

const isString = value => typeof value === 'string' || value instanceof String;

export default {
  isEmpty,
  isEmail,
  isObject,
  isString
};

