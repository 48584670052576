/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import HeaderLogo from '../header-logo';
import SecondaryNavBand from '../secondary-nav-band-wsn';
import classnames from 'classnames';
import { useFixedPositions } from '../../../../providers/fixed-position-provider/fixed-position-provider';
import SignIn from '../sign-in';

const HeaderDesktop = ({
  isLoggedIn, navProps, secondaryLinks, ctaProps, showCtaUndocked, partnerLogo
}) => {
  const { subscribeToFixedPosition, unsubscribeToFixedPosition } = useFixedPositions();
  const hasSecondaryBar = ( (ctaProps && showCtaUndocked) || secondaryLinks.length > 0 );
  const [secondaryNavDocked, setSecondaryNavDocked] = useState(false);
  const [{ showHeader, visibleCta, visibleLogo }, setVisibleComponents] = useState({
    showHeader: true,
    visibleCta: false,
    visibleLogo: false
  });

  const canScroll = useRef(true);

  useEffect(() => {
    let prevScrollYPos = window.scrollY;
    let prevScrollYTop = window.document.documentElement.scrollTop;
    const handleNavState = e => {
      e.preventDefault();

      if (!hasSecondaryBar) return;

      let isUp = () => prevScrollYTop > window.document.documentElement.scrollTop || prevScrollYPos > window.scrollY;
      if (window.document.documentElement.scrollTop < 25 || window.scrollY < 25) {
        setVisibleComponents({ showHeader: true, visibleCta: false, visibleLogo: false });
      } else if (canScroll.current) {
        canScroll.current = false;

        if (isUp()) {
          setVisibleComponents({ showHeader: true, visibleCta: true, visibleLogo: false });
        } else {
          setVisibleComponents({ showHeader: false, visibleCta: true, visibleLogo: true });
        }

        setTimeout(() => {
          canScroll.current = true;
        }, 600);
      }
      prevScrollYTop = window.document.documentElement.scrollTop;
      prevScrollYPos = window.scrollY;
    };

    window.addEventListener('scroll', handleNavState);

    return () => {
      window.removeEventListener('scroll', handleNavState);
    };
  }, [hasSecondaryBar]);

  useEffect(() => {
    const subscription = subscribeToFixedPosition();
    subscription.setOffsetTop(56);
    return () => {
      unsubscribeToFixedPosition(subscription);
    };
  }, [subscribeToFixedPosition, unsubscribeToFixedPosition]);

  useEffect(() => {
    if (!showHeader) {
      setSecondaryNavDocked(true);
    } else {
      setSecondaryNavDocked(false);
    }
  }, [showHeader]);

  const renderItemsOrLogo = () => {
    if (partnerLogo) {
      return (
        <img alt="Partner Logo" src={ partnerLogo } className="GlobalHeaderDesktop__logo--partner" />
      );
    }

    return (
      <nav className="HeaderNav__nav">
      <SignIn isLoggedIn={ isLoggedIn } />
      </nav>
    );
  };

  const renderSecondaryNav = () => {
    if ( !hasSecondaryBar ) {
      return null;
    }
    return (
      <div className={ classnames('GlobalsecondaryNavWrapper', { docked: !showHeader } ) }>
        <SecondaryNavBand
          tabs={ secondaryLinks }
          ctaProps={ ctaProps }
          showCtaUndocked={ showCtaUndocked }
          visibleCta={ visibleCta }
          visibleLogo={ visibleLogo }
          secondaryNavDocked={ secondaryNavDocked }
        />
      </div>
    );
  };

  return (
    <div className={classnames('Globalmain-header', {'hasBothHeaders': hasSecondaryBar && showHeader})}>
      <header className={classnames('GlobalHeaderDesktop', {'hidden': !showHeader})}>
        <div className="GlobalHeaderDesktop__container">
          <div className="GlobalHeaderDesktop__logo">
            <HeaderLogo theme="blue" />
          </div>
          { renderItemsOrLogo() }
        </div>
      </header>
      { renderSecondaryNav() }
    </div>
  );
};

HeaderDesktop.propTypes = {
  isLoggedIn: PropTypes.bool,
  ctaProps: PropTypes.object,
  navProps: PropTypes.any,
  showCtaUndocked: PropTypes.bool,
  secondaryLinks: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string
    })
  ).isRequired,
  partnerLogo: PropTypes.string
};

export default HeaderDesktop;

require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/components/global-header/components/header-desktop-wsn/header-desktop-wsn.scss');
require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/components/global-header/components/header-desktop-wsn/images/dropdown.png');