var $ = require('lc-jquery');
var each = require('lodash.foreach');

/**
 * The part of form manager that deals with fields
 *
 * @constructor
 * @classdesc Handles per-foeld interactions inside FormManager
 * @param {Object|String} form - A jQuery object, direct DOM reference, or selector string
 */
function FieldManager(form) {
  this.$form = (form instanceof $ || form.constructor.prototype.jquery) ? form : $(form);
  this.addListeners();
}

/**
 * Add event listeners for evends needed to do validation on changes to form inputs
 * @function addListeners
 * @memberof FieldManager
 */
FieldManager.prototype.addListeners = function addListeners() {
  this.formFieldEvents = {
    'input[type="text"]': 'focusout',
    'input[type="password"]': 'focusout',
    'input[type="ssn"]': 'focusout',
    'input[type="tel"]': 'focusout',
    'input[type="email"]': 'focusout',
    'input[type="number"]': 'focusout',
    'input[type="search"]': 'focusout',
    'textarea': 'focusout',
    'input[type="range"]': 'change', // unsure change is better for range
    'input[type="checkbox"]': 'change', // only useful for groups of checkboxes
    'select': 'change'
  };
  var self = this;
  each(this.formFieldEvents, function(evt, selector) {

    self.$form.on('focusin', selector, function(event) {
      self.setFieldStateIfUnset(event.currentTarget, 'is-active');
    });

    self.$form.on('focusout', selector, function(event) {
      self.setFieldStateIfUnset(event.currentTarget, null);
    });
  });
};

/**
 * Add event listeners for evends needed to do validation on changes to form inputs
 * @function addFieldValidationListeners
 * @memberof FieldManager
 # @param {Object} formValidator an instance of ui-form-validator
 */
FieldManager.prototype.addFieldValidationListeners = function addFieldValidationListeners(formValidator) {
  var self = this;

  // trigger field validation for all fields
  each(this.formFieldEvents, function(event, selector) {
    self.$form.on(event, selector, function(e) {
      // if this field doesn't have a name (remember-me checkbox, button, etc)
      // don't bother validating
      if (!e.target.name) {
        return;
      }
      // if this field hasn't been 'updated' by the user, don't validate
      // instead just remove the activeState
      if (!self.hasFieldBeenInteractedWith(e.target.name)) {
        self.setFieldsState(null, [e.target.name]);
        return;
      }
      formValidator.validate([e.target.name]);
    });
  });
};

/**
 * Set the visual state of a form field. Can also be used to revert back to default state
 * @function setFieldState
 * @memberof FieldManager
 * @instance
 * @param {HTMLElement} $formControls - One or more form elements to adjust the state of
 * @param {String} newState - One of `is-invalid`, `is-valid`, or `is-active`. If left null will revert the $formControls to their default state
 */
FieldManager.prototype.setFieldState = function setFieldState($formControls, newState) {
  $formControls
    .removeClass('is-invalid')
    .removeClass('is-valid')
    .removeClass('is-active');
  if (newState) {
    $formControls.addClass(newState);
  }
};


/**
 * Toggle a form field visually to be invalid. If there's an error message, display it
 * @function showFieldError
 * @memberof FieldManager
 * @instance
 * @param {HTMLElement} $input - A form element to be marked as invalid
 * @param {String} [message] - Error message to display
 */
FieldManager.prototype.showFieldError = function showFieldError($input, message) {
  var $formControl = $input.closest('.form-control');
  this.setFieldState($formControl, 'is-invalid');

  if (message) {
    // use .html() in case error message has link, bold, etc.
    // regular text will still work.
    $formControl.find('.form-control__invalid-message').html(message);
  }
};

/**
 * Get the visual state of a form field.
 * @function setFieldStateIfUnset
 * @memberof FieldManager
 * @instance
 * @param {HTMLElement} element - a form field inside of a .form-control
 * @param {String} newState - state to assign if the form control does not already have any state
 */
FieldManager.prototype.setFieldStateIfUnset = function setFieldStateIfUnset(element, newState) {
  var $formControl = $(element).closest('.form-control');
  var state = this.getFieldState($formControl);
  if (!state) {
    this.setFieldState($formControl, newState);
  }
};

/**
 * Reset an array of fields via their name back to their default state
 * @function setFieldsState
 * @memberof FieldManager
 * @instance
 * @param {String} [newState] - One of `is-invalid`, `is-valid`, or `is-active`. If left null will revert fieldNames to their default state
 * @param {Array} fieldNames - array of field names
 */
FieldManager.prototype.setFieldsState = function setFieldsState(newState, fieldNames) {
  var self = this;
  each(fieldNames, function(fieldName) {
    var $formControl = self.$form.find('[name=' + fieldName + ']')
      .closest('.form-control');
    self.setFieldState($formControl, newState);
  });
};

/**
 * Determine if a field has either been interacted with; interacted meaned it has either has a value or has an invalid/valid state
 * @function hasFieldBeenInteractedWith
 * @memberof FieldManager
 * @instance
 * @param {String} fieldName - name attribute of one field
 */
FieldManager.prototype.hasFieldBeenInteractedWith = function hasFieldBeenInteractedWith(fieldName) {
  var $field = this.$form.find('[name=' + fieldName + ']');
  var $formControl = $field.closest('.form-control');
  var val = $field.val();
  var state = this.getFieldState($formControl);
  // interacted with means the field either has a value or a invalid/valid state;
  // if both are false pretend the user has not interacted with the field
  return val !== '' || (state !== null && state !== 'is-active');
};


/**
 * Get the visual state of a form field.
 * @function getFieldState
 * @memberof FieldManager
 * @instance
 * @param {HTMLElement} $formControl - A form elements to get the state of
 */
FieldManager.prototype.getFieldState = function getFieldState($formControl) {
  var states = ['is-invalid', 'is-valid', 'is-active'];
  var activeState = null;
  states.forEach(function(state) {
    if (!activeState && $formControl.hasClass(state)) {
      activeState = state;
    }
  });
  return activeState;
};

module.exports = FieldManager;

