import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ attributes: { viewbox, preserveaspectratio, ...attributes } = {}, children }) => {
  return (
    <svg
      viewBox={viewbox}
      preserveAspectRatio={preserveaspectratio}
      {...attributes}
    >
      {children}
    </svg>
  );
};

Svg.propTypes = {
  attributes: PropTypes.object,
  children: PropTypes.any
};

export const isAnSvgNode = node => node.name === 'svg';

export default Svg;

