var UiMoney = require('ui-money');
var validator = require('ui-validator');
var v = validator.validators;

// custom validator that checks if the value is a valid ui-money value
var isUiMoney = validator.createValidator(function(options, value, key, attributes) {
  var parsed = new UiMoney(value);
  if (isNaN(parsed.getValue())) {
    return 'Enter $1,000 to $40,000';
  }
});

// custom validator that wraps a existing validator and skips it if the value is undefined
var skipIfMissing = validator.createValidator(function(options, value, key, attributes) {
  if (value === undefined || value === '') {
    return;
  }
  return options.validateFn(value, key, attributes);
});

module.exports = {
  loanAmount: {
    required: v.required({
      message: 'Enter $1,000 to $40,000'
    }),
    dollarAmountRange: v.dollarAmountRange({
      min: 1000,
      max: 40000,
      message: 'Enter $1,000 to $40,000'
    }),
    isUiMoney: skipIfMissing({validateFn: isUiMoney()})
  },
  loanPurpose: {
    required: v.required({
      message: 'Select a loan purpose'
    })
  },
  creditScore: {
    required: v.required({
      message: 'Select your credit score'
    })
  }
};

