import React, { createContext, useContext, useState, useEffect } from 'react';
import { getDefaultWindowDimensions } from './responsiveDefaults';
import PropTypes from 'prop-types';

const WindowDimensionsCtx = createContext(null);

const getDefaultDimensions = (context) => {
  if (typeof window !== 'undefined') {
    return {
      defaultHeight: window.innerHeight,
      defaultWidth: window.innerWidth
    };
  }

  return getDefaultWindowDimensions(context && context.agent);
};

const WindowDimensionsProvider = ({ context, children }) => {
  const { defaultWidth, defaultHeight } = getDefaultDimensions(context);

  const [dimensions, setDimensions] = useState({
    width: defaultWidth,
    height: defaultHeight
  });

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <WindowDimensionsCtx.Provider value={dimensions}>
      {children}
    </WindowDimensionsCtx.Provider>
  );
};

WindowDimensionsProvider.defaultProps = {
  context: {}
};

WindowDimensionsProvider.propTypes = {
  context: PropTypes.object,
  children: PropTypes.object.isRequired
};

export default WindowDimensionsProvider;
export const useWindowDimensions = () => useContext(WindowDimensionsCtx);

