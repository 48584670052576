// xD-TODO: move to core/utilities

function manageBoundaries(offsets) {
  offsets = offsets || {};
  var boundaries = {};
  var h = window.innerHeight || document.documentElement.clientHeight;
  var w = window.innerWidth || document.documentElement.clientWidth;

  boundaries.top = offsets.top || 0;
  boundaries.bottom = h - (offsets.bottom || 0);

  boundaries.left = offsets.left || 0;
  boundaries.right = w - (offsets.right || 0);

  return boundaries;
}

function isElementInViewport($element, offsets) {
  var el = $element.get(0);
  var rect = el.getBoundingClientRect();
  var boundaries = manageBoundaries(offsets);

  return (
    rect.top >= boundaries.top &&
    rect.left >= boundaries.left &&
    rect.bottom <= boundaries.bottom &&
    rect.right <= boundaries.right
  );
}

module.exports = isElementInViewport;

