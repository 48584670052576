import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import HeaderLogo from '../header-logo';
import HamburguerButton from './components/hamburguer-button';
import useBodyClass from '../../../../hooks/useBodyClass';
import { useFixedPositions } from '../../../../providers/fixed-position-provider/fixed-position-provider';
import SignIn from '../sign-in';

// eslint-disable-next-line max-lines-per-function
const HeaderMobile = ({ isLoggedIn, navProps, className, theme, showMenuLinks, partnerLogo, secondaryLinks }) => {
  const subMenuHeapTrack = async(analytics_key) => {};
  const [showMenu, setShowMenu] = useState(false);
  const hasSecondaryBar = ( secondaryLinks.length > 0 );
  const [secondaryNavBar, setSecondaryNavBar] = useState(false);
  const [sidebar, setSidebar] = useState(false);
  const {
    subscribeToFixedPosition,
    unsubscribeToFixedPosition
  } = useFixedPositions();

  useBodyClass(showMenu ? 'is-open-mobile-menu' : 'is-closed-mobile-menu');

  useEffect(() => {
    const subscription = subscribeToFixedPosition();
    subscription.setOffsetTop(50);
    return () => {
      unsubscribeToFixedPosition(subscription);
    };
  }, [subscribeToFixedPosition, unsubscribeToFixedPosition]);

  const hamburguerHandler = isOpen => {
    if (hasSecondaryBar) {
      setSecondaryNavBar(!isOpen);
    } else {
      setShowMenu(!isOpen);
    }
  };

  const showSidebar = () => {
    setSidebar(!sidebar);
  };

  const tabsLength = secondaryLinks.length;
  const tabsDifferentThanFive = tabsLength !== 5;

  const classNamesItemNarrowContent = (active) => classNames({
    'leaf-content': tabsDifferentThanFive,
    'leaf-narrow': !tabsDifferentThanFive,
    'leaf-content--active': tabsDifferentThanFive && active,
    'leaf-narrow--active': !tabsDifferentThanFive && active
  });

  const renderMenuItems = () => {
    return showMenuLinks ? (
      <div
        className={classNames('GlobalHeaderMobile__slidingMenu', {
          'GlobalHeaderMobile__slidingMenu--show': showMenu || secondaryNavBar
        })}
      >
        <div className={classNames('GlobalHeaderMobile__Navs GlobalHeaderMobile__subnav', {
          'GlobalHeaderMobile__subnav--show': secondaryNavBar, 'GlobalHeaderMobile__subnav--hide': sidebar})}>
          { renderSecondaryTabs() }
        </div>
      </div>
    ) : null;
  };

  const renderSecondaryTabs = () => {
    if ( !hasSecondaryBar ) {
      return null;
    }
    return (
      <ul className='nav-list'>
        <li className="nav-back" onClick={showSidebar}>Main Menu</li>
        {secondaryLinks.map((item, index) => {
        const analytics_key = item.text.toLowerCase().split(' ').join('_');
          return (
            <li key={index} className="leaf">
              <a
                id={`list-item-a-${index}`}
                href={item.url}
                className={classNamesItemNarrowContent(item.active)}
                onClick={() => subMenuHeapTrack(analytics_key)}
              >
              {item.text}
              </a>
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <header
      className={classNames('GlobalHeaderMobile', className, {
        [`GlobalHeaderMobile--${theme}`]: !!theme,
        'GlobalHeaderMobile--open': !!showMenu
      })}
    >
      <div className="GlobalHeaderMobile__wrapper">
        <HeaderLogo theme="blue" />
        <SignIn isLoggedIn={ isLoggedIn } />
        {showMenuLinks ? (
          <HamburguerButton toggleCallback={hamburguerHandler} />
        ) : null}
        {partnerLogo && <img alt="Partner Logo" src={ partnerLogo } className="GlobalHeaderMobile__logo--partner" />}
      </div>
      {renderMenuItems()}
    </header>
  );
};

HeaderMobile.defaultProps = {
  theme: 'default',
  showMenuLinks: true
};

HeaderMobile.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  navProps: PropTypes.object,
  showMenuLinks: PropTypes.bool,
  partnerLogo: PropTypes.string,
  secondaryLinks: PropTypes.array
};

export default React.memo(HeaderMobile);

require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/components/global-header/components/header-mobile/header-mobile.scss');