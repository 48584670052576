var $ = require('lc-jquery');

function Spinner(container) {
  var isJquery = container && (container instanceof $ || container.constructor.prototype.jquery);
  this.$container = isJquery ? container : $(container);
}

Spinner.prototype.show = function() {
  this.$container.removeClass('hidden');
};

Spinner.prototype.hide = function() {
  this.$container.addClass('hidden');
};

module.exports = Spinner;

require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/xd-spinner/src/images/spinner.svg');