'use strict';

var $ = require('lc-jquery');
var Accordion = require('../../xd-accordion');
var debounce = require('lodash.debounce');
var compareAgainstBreakpoint = require('ui-experience-system/core/breakpoint-compare');
var Footnotes = require('footnotes');
var scrollToElement = require('ui-experience-system/core/scroll-to-element');

function Footer(container) {
  var isJquery = container && (container instanceof $ || container.constructor.prototype.jquery);
  this.$container = isJquery ? container : $(container);
  this.footnotes = new Footnotes(this.$container);
  this.addEventListeners();
  this.setupAccordion();
}

Footer.prototype.addEventListeners = function addEventListeners() {
  var disclaimerContent = this.$container.find('[data-disclaimer-content]');
  var disclaimerOffset = $(window).height() - 78;
  this.$container.find('.footer__disclaimers-title')
    .on('click', function() {
      scrollToElement(disclaimerContent, 600, disclaimerOffset);
    });

  var resizeFunction = debounce($.proxy(this.toggleAccordion, this), 100);
  $(window).on('resize', resizeFunction);
};

Footer.prototype.toggleAccordion = function toggleAccordion(event) {
  if (compareAgainstBreakpoint('<', 'phone-large')) {
    // accordion should be shown, but do nothing if it's already active
    if (!this.isAccordionActive) {
      // add class so it gains accordion based styles
      this.$container.find('[data-footer-accordion]').addClass('accordion accordion--inverse');
      this.accordion.addEventListeners();
      this.isAccordionActive = true;
    }
  } else {
    // accordion should be hidden, but do nothing if it's already disabled
    if (this.isAccordionActive) {
      // remove class so it loses accordion based styles
      this.$container.find('[data-footer-accordion]').removeClass('accordion accordion--inverse');
      this.accordion.disable();
      this.isAccordionActive = false;
    }
  }
};

Footer.prototype.setupAccordion = function setupAccordion() {
  // need to initialize it, but don't assume it's active until we
  // check the width of the document, so immediately disable before checking
  this.isAccordionActive = false;
  this.accordion = new Accordion($('[data-footer-accordion]'));
  this.accordion.disable();
  this.toggleAccordion();
};

new Footer('[data-site-footer]');

module.exports = Footer;

require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/xd-site-footer/src/images/email.svg');
require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/xd-site-footer/src/images/fb.svg');
require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/xd-site-footer/src/images/footer-bbb.svg');
require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/xd-site-footer/src/images/footer-ehl.svg');
require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/xd-site-footer/src/images/footer-verisign.svg');
require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/xd-site-footer/src/images/LC_logo_reverse.svg');
require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/xd-site-footer/src/images/logo_better_business_bureau_black.svg');
require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/xd-site-footer/src/images/logo_equal_housing_lender_black.svg');
require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/xd-site-footer/src/images/logo_etrust_black.svg');
require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/xd-site-footer/src/images/logo_lctm.png');
require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/xd-site-footer/src/images/logo_verisign_black.svg');
require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/xd-site-footer/src/images/twitter.svg');