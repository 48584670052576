// This is a generic entry point for React apps, add all global context providers needed here
import React from 'react';
import PropTypes from 'prop-types';
import 'lazysizes';

import WindowDimensionsProvider from '../window-dimensions-provider/windowDimensionsProvider';
import SessionProvider from '../session-provider/session-provider';
import InteractionProvider from '../interaction-provider/interactionProvider';
import FixedPositionProvider from '../fixed-position-provider/fixed-position-provider';

const BaseProviders = ({ children, windowDimensionsProps, sessionDataProps, fixedPositionProps }) => {
  return (
    <WindowDimensionsProvider {...windowDimensionsProps}>
      <SessionProvider {...sessionDataProps} >
        <InteractionProvider>
          <FixedPositionProvider {...fixedPositionProps} >
          {children}
          </FixedPositionProvider>
        </InteractionProvider>
      </SessionProvider>
    </WindowDimensionsProvider>
  );
};

BaseProviders.propTypes = {
  children: PropTypes.any,
  windowDimensionsProps: PropTypes.object,
  sessionDataProps: PropTypes.object,
  fixedPositionProps: PropTypes.object
};

export default BaseProviders;

