var validator = require('ui-validator');
var v = validator.validators;

module.exports = {
  email: {
    required: v.required({message: 'Enter a valid email address'}),
    email: v.email({message: 'Enter a valid email address'})
  },
  password: {
    required: v.required({message: 'Enter your password'}),
    suspicious: v.suspicious({message: 'This password is not valid'}),
    inputLength: v.inputLength({min: 8, max: 40, message: 'This password is not valid'})
  }
};

