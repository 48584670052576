import React from 'react';
import { Grid } from '@ui-propulsion/react-components';
import PropTypes from 'prop-types';
import CMSParser from '../../../../components/cms-parser';
import replaceTelephoneLink from '../../../../../shared_modules/utils/replaceTelephoneLink';

const FooterGlobalTrustedBadge = ({ address, sectionDescription, ctaUrl, ctaLabel }) => {
    return (
        <>
            <div className="footer-global-trusted-badge">
                <Grid container>
                    <Grid item xs={12} sm={4} lg={3}>
                        <div className="logo-section">
                            <a href="/" className="footer-logo"></a>
                            <div className="footer-address">
                                {CMSParser(address)}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={8} lg={9}>
                        <Grid container>
                            <Grid item xs={12} sm={8} lg={4}>
                                <div className="contact-section">
                                    {CMSParser(replaceTelephoneLink(sectionDescription))}
                                </div>
                            </Grid>
                            <Grid item xs={12} lg={8}>
                                <div className="copyright-section">
                                    <a className="copyright-logo copyright-logo-equal-housing"></a>
                                    <a href="https://www.bbb.org/us/ca/san-francisco/profile/financial-services/lendingclub-corporation-1116-361746" target="_blank" rel="noopener noreferrer" className="copyright-logo copyright-logo-accredited-business"></a>
                                    <a className="copyright-logo copyright-logo-veri-sign"></a>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

FooterGlobalTrustedBadge.defaultProps = {
    address: '',
    sectionDescription: ''
};

FooterGlobalTrustedBadge.propTypes = {
    address: PropTypes.string,
    sectionDescription: PropTypes.string,
    ctaLabel: PropTypes.string,
    ctaUrl: PropTypes.string
};

export default FooterGlobalTrustedBadge;

require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/components/footer-global/components/footer-global-trusted-badge/footer-global-trusted-badge.scss');