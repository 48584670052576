const $ = require('lc-jquery');
const Modal = require('../xd-modal');


function SmsAgreementModal(container) {
  // don't initialize jQuery around a jQuery object, since that does a shallow copy
  this.$container = container instanceof $ ? container : $(container);
  this.modal = new Modal(this.$container);
}

module.exports = SmsAgreementModal;

