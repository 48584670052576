import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import createRandomId from '../../utils/createRandomId';

const FixedPositionCtx = createContext({});
const getContextPositions = (context) => {
  return {
    offsetTop: context.offsetTop,
    offsetBottom: context.offsetBottom,
    offsetLeft: context.offsetLeft,
    offsetRight: context.offsetRight
  };
};

const subscriberFactory = (updatePositions, subscribers) => ({ offsetTop = 0, offsetBottom = 0, offsetLeft = 0, offsetRight = 0 } = {}) => {
  const subscriber = {
    id: createRandomId('fixed-position-subscriber'),
    offsetTop, offsetLeft, offsetRight, offsetBottom
  };
  const setSubscriberOffset = position => offset => {
    if (typeof offset !== 'number') {
      throw new Error('offset should be a number');
    }
    subscriber[position] = Math.max(0, offset);
    updatePositions();
  };
  subscriber.setOffsetTop = setSubscriberOffset('offsetTop');
  subscriber.setOffsetBottom = setSubscriberOffset('offsetBottom');
  subscriber.setOffsetLeft = setSubscriberOffset('offsetLeft');
  subscriber.setOffsetRight = setSubscriberOffset('offsetRight');
  subscribers.push(subscriber);
  updatePositions();
  return subscriber;
};

const FixedPositionProvider = ({ context, children }) => {

  const subscribers = [];
  const updatePositions = () => {
    const newPositions = subscribers.reduce((position, subscriber) => {
      position.offsetTop += subscriber.offsetTop;
      position.offsetBottom += subscriber.offsetBottom;
      position.offsetLeft += subscriber.offsetLeft;
      position.offsetRight += subscriber.offsetRight;
      return position;
    }, getContextPositions(context));
    setFixedPosition({
      ...fixedPositions,
      ...newPositions
    });
  };

  const [fixedPositions, setFixedPosition] = useState({
    ...getContextPositions(context),
    subscribeToFixedPosition: subscriberFactory(updatePositions, subscribers),
    unsubscribeToFixedPosition: ({ id }) => {
      const subscriberIndex = subscribers.find(subscriber => id === subscriber.id);
      subscribers.splice(subscriberIndex, 1);
      updatePositions();
    }
  });

  return (
    <FixedPositionCtx.Provider value={fixedPositions}>
      {children}
    </FixedPositionCtx.Provider>
  );
};

FixedPositionProvider.defaultProps = {
  context: {
    offsetTop: 0,
    offsetLeft: 0,
    offsetBottom: 0,
    offsetRight: 0
  }
};

FixedPositionProvider.propTypes = {
  context: PropTypes.shape({
    offsetTop: PropTypes.number,
    offsetLeft: PropTypes.number,
    offsetBottom: PropTypes.number,
    offsetRight: PropTypes.number
  }),
  children: PropTypes.any.isRequired
};

export default FixedPositionProvider;
export const useFixedPositions = () => useContext(FixedPositionCtx);

