import React from 'react';
import PropTypes from 'prop-types';
import { useActions } from '../../../../providers/actions-provider/actions-provider';

const actionMatcher = /^["']?#action--(.+?)["']?$/;

const LinkAction = ({ href, children }) => {
  const actions = useActions();

  const clickHandler = (evt) => {
    const [, actionName] = href.match(actionMatcher) || [];
    const action = actions[actionName];
    if (typeof action === 'function') {
      evt.preventDefault();
      action(evt);
    }
  };

  const getSanitizedHref = () => {
    const [, actionName = ''] = href.match(actionMatcher) || [];
    return `#action--${actionName}`;
  };

  return <a href={getSanitizedHref()} onClick={clickHandler}>{children}</a>;
};

LinkAction.defaultProps = {
  children: '*'
};

LinkAction.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.any
};

export default LinkAction;

export const isALinkAction = (node) => {
  return node.name === 'a' && node.attribs && node.attribs.href.match(actionMatcher);
};

