var $ = require('lc-jquery');

function Modal(container, options) {
  this.setupContainers(container);
  this.setTransitionEventName();
  this.setOptions(options);
  this.adjustOptionalElements();
  this.addListeners();
  this.isShowing = false;
}

Modal.prototype.setupContainers = function setupContainers(container) {
  // don't initialize jQuery around a jQuery object, since that does a shallow copy
  this.$container = container instanceof $ ? container : $(container);
  this.dialog = this.$container.find('[data-modal-dialog]');
  this.bodyNode = $('body');
};

// xD-TODO - move this into Utilites?
Modal.prototype.setTransitionEventName = function setTransitionEventName() {
  var element = document.createElement('fakeelement');
  var transitions = {
    'transition': 'transitionend',
    'OTransition': 'oTransitionEnd',
    'MozTransition': 'transitionend',
    'WebkitTransition': 'webkitTransitionEnd'
  };

  var transition;
  for (transition in transitions) {
    if (element.style[transition] !== undefined && !this.transitionEnd) {
      this.transitionEnd = transitions[transition];
    }
  }
};

Modal.prototype.setOptions = function setOptions(opts) {
  opts = typeof opts === 'object' ? opts : {};
  this.defaultOptions = {
    dimmer: true,
    dimmerClose: true,
    header: true,
    closeButton: true,
    keyboardHide: true
  };

  this.options = $.extend({}, this.defaultOptions, opts);
};

Modal.prototype.adjustOptionalElements = function adjustOptionalElements() {
  if (!this.options.header) {
    this.$container.find('.modal-header').addClass('hidden');
  } else if (!this.options.closeButton) {
    // only need to check closeButton if header is visible
    this.$container.find('.modal-header__close').addClass('hidden');
  }
  if (!this.options.dimmer) {
    this.$container.find('.modal__dimmer').addClass('hidden');
  }
};

Modal.prototype.addListeners = function addListeners() {
  if (this.options.closeButton) {
    this.$container.find('[data-modal-hider]')
      .on('click', $.proxy(this.hide, this));
  }
  if (this.options.keyboardHide) {
    $(document).on('keydown', $.proxy(this.checkKeyPress, this));
  }
  if (this.options.dimmer && this.options.dimmerClose) {
    this.$container.find('[data-modal-dimmer]')
      .on('click', $.proxy(this.hide, this));
  }

  // openers are not in modal, so don't constrain to container
  var showHandler = $.proxy(this.show, this);
  $('[data-modal-opener="' + this.dialog.data('modal-dialog') + '"]').each(function() {
    var $opener = $(this);
    var evtName = $opener.attr('data-modal-event') || 'click';
    $opener.on(evtName, showHandler);
  });
};

Modal.prototype.show = function show(evt, forceShow) {
  // only show this modal if the opener was for it, or if forced to
  // do so by a direct JS call
  if (!forceShow && $(evt.currentTarget).data('modal-opener') !== this.dialog.data('modal-dialog')) {
    return true; // let event propagate to other modal dialogs, if any
  }

  // correct opener was activated, show the modals
  this.dialog
    .addClass('modal--showing')
    .one(this.transitionEnd, $.proxy(this.finishShowing, this));

  // don't let other modals or other handlers try to catch same event
  evt.preventDefault();
  evt.stopPropagation();
  evt.stopImmediatePropagation();

  // Adding this css property to body prevents it from scrolling when the modal is shown.
  this.bodyNode
    .css('overflow', 'hidden');

  // fire an event any modal container can listen for;
  // video-modal does this as an example
  this.$container.trigger('modal:opened');
  return false;
};

Modal.prototype.finishShowing = function finishShowing() {
  this.dialog.attr('aria-hidden', false);
  this.isShowing = true;
  this.dialog.find('input, select')
    .filter(':visible')
    .first()
    .focus();
};

Modal.prototype.hide = function hide(event) {
  event.preventDefault();
  this.$container.find('input, select')
    .prop('disabled', true);
  this.dialog
    .removeClass('modal--showing')
    .addClass('modal--hiding ')
    .one(this.transitionEnd, $.proxy(this.finishHiding, this));

  // changes css that prevents body to scroll when the modal is open.
  this.bodyNode
    .css('overflow', '');

  // fire an event any modal container can listen for;
  // prequal-modal does this as an example
  this.$container.trigger('modal:closed');
};

Modal.prototype.finishHiding = function finishHiding() {
  this.dialog
    .removeClass('modal--hiding')
    .attr('aria-hidden', true);
  this.$container.find('input, select')
    .prop('disabled', false);
  this.isShowing = false;
};

Modal.prototype.checkKeyPress = function checkKeyPress(event) {
  if (!this.isShowing) {
    return true; // let propagate up in case other things listen for ESC key
  }
  var escapeKey = 27;
  if (event.which === escapeKey) {
    event.preventDefault();
    this.hide(event);
  }
};

module.exports = Modal;

