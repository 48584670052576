module.exports = {
    'FACTOR_LINK_TEXT': 'use a different verification method',
    'OTP_EMAIL': {
        title: 'We sent a code to your email'
    },
    'OTP_SMS': {
        title: 'We\'ve texted you with a code'
    },
    'OTP_VOICE': {
        title: 'We\'ll call you in a moment'
    }
};

