/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import http from 'lc-http';
import objectToQueryString from '../../utils/objectToQueryString';

import FooterGlobalTrustedBadge from './components/footer-global-trusted-badge';

const FooterGlobal = ({ postUrl }) => {
    const [trustedBadgeProps, setTrustedBadgeProps] = useState({});

    useEffect(() => {
      http.post(postUrl, objectToQueryString({ fragment: 'footer-menu-nav', prefix: 'footer-' })).then((res) => {
        setTrustedBadgeProps(res.data.trustedBadge);
      }).catch((e) => {});
    }, [postUrl]);

    return (
        <footer className="footer-global">
            <div className="footer__container">
                <div className="footer__section">
                    <FooterGlobalTrustedBadge {...trustedBadgeProps} />
                </div>
            </div>
        </footer>
    );
};

FooterGlobal.defaultProps = {
    postUrl: '/auth/menu-nav'
};

FooterGlobal.propTypes = {
    /* string with api end point to get footer data */
    postUrl: PropTypes.string
};

export default FooterGlobal;

require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/components/footer-global/footer-global.scss');
require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/components/footer-global/images/accredited-business.png');
require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/components/footer-global/images/accredited-business.svg');
require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/components/footer-global/images/equal-housing.png');
require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/components/footer-global/images/equal-housing.svg');
require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/components/footer-global/images/facebook.svg');
require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/components/footer-global/images/footer-logo.png');
require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/components/footer-global/images/footer-logo.svg');
require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/components/footer-global/images/footer-menu-lc-logo.png');
require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/components/footer-global/images/footer-menu-lc-logo.svg');
require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/components/footer-global/images/instagram.svg');
require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/components/footer-global/images/lc_newlogo_footer.png');
require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/components/footer-global/images/linkedin.svg');
require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/components/footer-global/images/twitter.svg');
require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/components/footer-global/images/veri-sign.png');
require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/components/footer-global/images/veri-sign.svg');