import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

const TableCell = ({ children, header }) => {

  const renderCell = header ? (
    <th className="DataTable__cell DataTable__cell--header">
      {children}
    </th>
  ) : (
    <td className="DataTable__cell">
      {ReactHtmlParser(children)}
    </td>
  );

  return (renderCell);
};

TableCell.propTypes = {
  children: PropTypes.any,
  header: PropTypes.bool
};

export default TableCell;

