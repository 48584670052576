var FormManager = require('ui-form-manager');
var toNumber = require('ui-formatter').formatters.toNumber;
var validatorSchema = require('./schema-validators');
var formatterSchema = require('./schema-formatters');
var $ = require('lc-jquery');

/**
 * Represents the main interactions needed by most prequal forms.
 *
 * @constructor
 * @param {Object|String} container - A jQuery instance of the container/form, a string selector to that dom element, or the dom element directly
 * @param {Object} prequalOptions - Options that specifies submitControl, detectSpecifiedCallback
 * @param {Object} formOptions - Options that specifies form schema,
 *                 service endPoint, errorHandler, submitControl,
 *                 nativeSubmit, allowTracking, hideErrors, and
 *                 spinner usage
 */
function PrequalCommon(container, prequalOptions, formOptions) {
  // don't initialize jQuery around a jQuery object, since that does a shallow copy
  this.$form = container instanceof $ ? container : $(container);
  this.options = this.getPrequalOptions(prequalOptions || {});

  formOptions = this.getFormOptions(formOptions || {});
  this.formManager = new FormManager(this.$form, formOptions);

  // workaround for https://jira.tlcinternal.com/browse/UIP-2047
  this.formManager.nativeSubmitForm.addClass('hidden');
  this.$form.after(this.formManager.nativeSubmitForm);

  this.$formFields = this.$form.find('input, select');
  this.$formFields.on('keyup change', $.proxy(this.detectSpecified, this));

  // give button disabled class unless form fields were prefilled
  this.toggleSubmitControl();

  // show next step in case form is partially prefilled
  this.adjustNextStep();

  // make sure digital keyboards don't get stuck open hiding errors
  this.handleSoftwareKeyboard();

  this.checkIfValidBusinessLoan();
}

PrequalCommon.prototype.getPrequalOptions = function getPrequalOptions(prequalOptions) {
  var defaults = {
    submitControl: this.$form.find('button[type=submit]'),
    detectSpecifiedCallback: null // don't need, just being explicit what options are available
  };
  // deep merge
  return $.extend(true, defaults, prequalOptions);
};

PrequalCommon.prototype.getFormOptions = function getFormOptions(formOptions) {
  var defaults = {
    endPoint: {
      // will need localproxy to work on dev
      url: '/apply/personal/identity',
      method: 'GET',
      nativeSubmit: true
    },
    errorHander: $.proxy(this.errorHandler, this),
    submitControl: this.$form.find(this.options.submitControl),
    spinner: true,
    validatorSchema: validatorSchema,
    formatterSchema: formatterSchema
  };
  // deep merge
  return $.extend(true, defaults, formOptions);
};

PrequalCommon.prototype.detectSpecified = function detectSpecified(evt) {
  // specified here means the user has specified (entered/selected)
  // a value; e.g., the value is no longer the default
  // i
  var $field = $(evt.target);
  if ($field.val()) {
    $field.addClass('specified');
  } else {
    $field.removeClass('specified');
  }

  this.adjustNextStep();
  this.toggleSubmitControl();

  if (this.options.detectSpecifiedCallback) {
    this.options.detectSpecifiedCallback();
  }
};

PrequalCommon.prototype.toggleSubmitControl = function toggleSubmitControl() {
  // if all fields specified, then disable submitControl visually
  var allFieldsSpecified = this.$formFields.length === this.$formFields.filter('.specified').length;
  this.$form.find(this.options.submitControl)
    .toggleClass('disabled', !allFieldsSpecified);
};

PrequalCommon.prototype.adjustNextStep = function adjustNextStep() {
  // find the "next step", accounting for radio buttons, etc.
  var $fields = this.$form.find('[data-form-step]');
  var $nextField = $fields.not('.specified').first();

  // manage CSS classes on next step, old and new
  $fields.removeClass('next-step');
  $nextField
    .addClass('next-step')
    .removeClass('disabled'); // for the submitControl
};

PrequalCommon.prototype.handleSoftwareKeyboard = function handleSoftwareKeyboard() {
  this.$form.on('submit', function(event) {
    var activeElement = document.activeElement;

    // blur active element if it is a formField to close keyboard
    if (activeElement) {
      var tag = activeElement.tagName.toLowerCase();
      var tags = ['input', 'select', 'textarea', 'button'];

      // if we have activeElement but it is not a form field, no reason to blur() on it
      if (tags.indexOf(tag) >= 0) {
        activeElement.blur();
      }
    } else {
      // browser doesn't have activeElement, blur all of our form fields to be sure
      this.$formFields.blur();
    }

    // let browser continue to submit or show errors via formManager;
    // needs to be an indeterminate return (true submits even if errors, false stops submission)
    return;
  });
};

PrequalCommon.prototype.errorHandler = function errorHandler(errs) {
  console.error(errs);
  return false;
};

PrequalCommon.prototype.checkIfValidBusinessLoan = function checkIfValidBusinessLoan() {
  // overload submit handler (for 2nd or 3rd time, depending on whether or not button opens modal)
  this.formManager.nativeSubmitForm.on('submit', function(event) {
    var minAmount = 5000;
    var values = {
      loanAmount: toNumber(this.$form.find('[name=loanAmount]').val()),
      loanPurpose: this.$form.find('[name=loanPurpose]').val(),
      creditScore: this.$form.find('[name=creditScore]').val()
      // referrerId is handled in .hbs from res.locals
    };

    if (values.loanAmount >= minAmount && values.loanPurpose === 'small_business' && values.creditScore !== '') {

      // fire custom event that prequal-modal is listening for.
      // we're passing the values of whatever prequal we've scraped; it
      // could be from any prequal that has all fields exposed (so it
      // may or may not already be in a modal)
      $('[data-prequal-modal]').trigger('prequal:interstitial', [values]);

      // Enable the form to be validated again
      this.formManager.unmaskForm();
      this.toggleSubmitControl();
      return false; //  prevent the form to be submitted
    }

    return true; // let browser continue to submit
  }.bind(this));
};

module.exports = PrequalCommon;

