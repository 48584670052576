/**
 * Defines the set of default dimensions in case we don't have access
 * to the window (browser) object.
 * Note: This values were extracted from Apple devices.
 * @param {Object} agent Contains boreas sizes (comes from backend)
 */
export const getDefaultWindowDimensions = agent => {
  if (agent && agent.isDesktop) {
    return {
      defaultWidth: 1024,
      defaultHeight: 768
    };
  } else if (agent && agent.isTablet) {
    return {
      defaultWidth: 768,
      defaultHeight: 1024
    };
  } else if (agent && agent.isMobile) {
    return {
      defaultWidth: 320,
      defaultHeight: 568
    };
  }
  return {
    defaultWidth: 0,
    defaultHeight: 0
  };
};

