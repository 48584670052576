var $ = require('lc-jquery');

function scrollToElement(element, speed, pixelOffset) {
  var elementTop = element.offset().top;
  pixelOffset = pixelOffset || 0;

  // speed can be a number (in milliseconds).
  // jquery suggets 400 for normal,
  // 200 for fast, and 600 for slow
  speed = speed || 600;
  $('html, body').animate({
    scrollTop: elementTop - pixelOffset
  }, speed);
}

module.exports = scrollToElement;

