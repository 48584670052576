import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const ActionsCtx = createContext({});

const ActionsProvider = ({ children, ...actions }) => {
  return (
    <ActionsCtx.Provider value={{ ...actions }}>
      {children}
    </ActionsCtx.Provider>
  );
};

ActionsProvider.propTypes = {
  children: PropTypes.any.isRequired
};

export default ActionsProvider;
export const useActions = () => useContext(ActionsCtx);

