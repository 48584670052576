import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const SessionCtx = createContext(null);

const SessionProvider = ({ isLoggedIn, accountInfo, children }) => {
  const sessionData = {
    isLoggedIn,
    accountInfo
  };

  return (
    <SessionCtx.Provider value={ sessionData }>
      { children }
    </SessionCtx.Provider>
  );
};

SessionProvider.defaultProps = {
  isLoggedIn: false,
  accountInfo: {}
};

SessionProvider.propTypes = {
  isLoggedIn: PropTypes.bool,
  accountInfo: PropTypes.object,
  children: PropTypes.object.isRequired
};

export default SessionProvider;
export const useSessionData = () => useContext(SessionCtx);

