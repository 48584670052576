var PrequalCommon = require('../../xd-prequal-common');
var $ = require('lc-jquery');
var Modal = require('../../xd-modal');


function PrequalModal(container, prequalOptions, formOptions) {
  // don't initialize jQuery around a jQuery object, since that does a shallow copy
  this.$container = container instanceof $ ? container : $(container);
  this.modal = new Modal(this.$container);

  this.$form = this.$container.find('[data-prequal-modal-form]');
  this.pq = new PrequalCommon(this.$form, prequalOptions, formOptions);
  this.addEventListeners();
}

PrequalModal.prototype.addEventListeners = function addEventListeners() {
  // let clicking on header work like CTA button for mobile
  this.$container.on('click', '.prequal-sb__section-header', $.proxy(this.headerClick, this));
  // custom event fired by prequal-common
  this.$container.on('prequal:interstitial', $.proxy(this.showBusinessInterstitial, this));
  // custom event fired by modal
  this.$container.on('modal:closed', $.proxy(this.hideBusinessInterstitial, this));
};

PrequalModal.prototype.headerClick = function headerClick(event) {
  // want to be able to click the header on mobile and submit form or click link.
  var $target = $(event.target);
  var $section = $target.closest('.prequal-sb__section');
  // cta can be either link or form submit button
  var $cta = $section.find('.prequal-sb__button');
  // trigger('click') worked on button but not <a>, unsure why not
  // but using click() on native element worked
  $cta.get(0).click();
};

PrequalModal.prototype.showBusinessInterstitial = function showBusinessInterstitial(event, values) {
  var $interstitial = this.$container.find('[data-prequal-sb]');

  // if modal is not shown, force show it without an event
  if (!this.modal.isShowing) {
    this.modal.show(event, true);
  }

  // toggle which part of modal is visible
  this.$form.addClass('hidden');
  $interstitial.removeClass('hidden');

  // update hidden form with values passed in to interstitial;
  // if customer decides to go ahead with personal loan over SB,
  // it'll submit to PI1 with values same as original prequal would have
  Object.keys(values).forEach(function(key) {
    $interstitial.find('input[type=hidden][name=' + key + ']').val(values[key]);
  });
};

PrequalModal.prototype.hideBusinessInterstitial = function hideBusinessInterstitial(event) {
  // want to change what's visible even if modal is closed
  // so that it shows correct section when opened next time
  this.$form.removeClass('hidden');
  this.$container.find('[data-prequal-sb]').addClass('hidden');
};

$('[data-prequal-modal]').each(function() {
  new PrequalModal(this);
});

module.exports = PrequalModal;

require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/xd-prequal-modal/src/images/chevron.svg');