var $ = require('lc-jquery');
var debounce = require('lodash.debounce');
var Accordion = require('../../xd-accordion');

/**
 * Set up the top navigation main menu
 * @param {jQueryElement} container    Element that contains the navigation header [data-header]
 * @param {jQueryElement} secondaryNav Element that contains the secondary nav, to trigger hiding of main menu and docking of secondary menu (optional)
 */
function Nav(container, secondaryNav, ctaScrollPoint) {
  this.container = container;
  this.secondaryNav = secondaryNav;
  this.tabs = container.find('[data-menu-tab]');
  this.panels = container.find('[data-menu-panel]'); // first level panel
  this.mobilePanel = this.panels.filter('[data-menu-panel=mobile]');
  this.tertiaryPanels = container.find('[data-tertiary-menu]'); // third level panel (second level is inside accordion)

  this.activeTab = null;
  this.activePanel = null;

  this.openMobileClass = 'is-open-mobile';
  this.activeNavClass = 'nav--active';
  this.activeTabClass = 'nav-tabs__item--active';
  this.activePanelClass = 'nav__content--active';
  this.mobilePanelScrollableClass = 'nav__content--scrollable';
  this.openMobileTertiaryClass = 'is-open-mobile-tertiary';
  this.activeTertiaryPanelClass = 'nav-mobile__tertiary-panel--active';

  this.ctaLinks = container.find('[data-nav-cta-link]');
  if (secondaryNav) {
    this.ctaLinks = this.ctaLinks.add(secondaryNav.find('[data-nav-cta-link]'));
  }
  this.ctaHideLinkClass = 'hide-cta';
  this.ctaScrollPoint = ctaScrollPoint; // End of hero, if exists, use as scroll point to show mobile CTA if initially hidden

  this.switchMenuTimeout = null; // remember the delayed hover show timeout
  this.menuOpenTime = 0; // remember when menu was opened

  this.lastWidth = $(document).width();
  this.lastScrollTop = $(document).scrollTop();

  this.desktopWidth = 900; // TODO: Use the checkBreakpoint function when it's available
  this.showingBigMenu = false; // set by handleResize()
  this.showingCta = false; // set by handleWindowScroll()

  this.accordion = new Accordion(this.container.find('[data-menu-accordion]'));

  this.hasOpenedMobile = false;

  this.handleResize(); // sets lastScrollTop and lastWidth

  this.addListeners();
}

/**
 * Return the open tab value
 */
Nav.prototype.getOpenTabName = function getOpenTabName() {
  return this.activeTab ? this.activeTab.data('menu-tab') : undefined;
};

/**
 * Switch the desktop menu tab
 * @param  {String} tab Menu panel to show.  If undefined, then close all panels.
 */
Nav.prototype.switchMenu = function switchMenu(tab) {
  this.switchMenuTimeout = null; // future timeout has been taken care of

  // skip action if requested tab is the current tab
  if (tab && tab === this.getOpenTabName()) {
    return;
  }

  // if not no activeTab then clear out highlighted tab acting as location breadcrumb
  if (!this.activeTab) {
    this.tabs.removeClass(this.activeTabClass);
  }

  if (this.activeTab) {
    this.activeTab.removeClass(this.activeTabClass);
    this.activeTab = null;
    this.activePanel.removeClass(this.activePanelClass).fadeOut(this.showingBigMenu ? 300 : 0); // fading only for desktop hover
    this.activePanel = null;
  }

  // add nav active class if tab is activated, otherwise remove it
  this.container.toggleClass(this.activeNavClass, !!tab);

  if (tab) {
    this.activeTab = this.tabs.filter('[data-menu-tab=' + tab + ']').addClass(this.activeTabClass);
    this.activePanel = this.panels.filter('[data-menu-panel=' + tab + ']').addClass(this.activePanelClass).fadeIn(this.showingBigMenu ? 100 : 0); // fading only for desktop hover

    this.menuOpenTime = new Date().getTime();
  }
};

Nav.prototype.setCtaVisibility = function toggleCta() {
  if (!this.ctaScrollPoint) {
    return;
  }
  var isBeforeScrollPoint = this.ctaScrollPoint.offset().top - this.container.height() > $(window).scrollTop();
  this.ctaLinks.toggleClass(this.ctaHideLinkClass, isBeforeScrollPoint);
};

/**
 * This is necessary to account for mobile toolbars that actually make the height smaller than 100vh :(
 * TODO: Test more phones
 */
Nav.prototype.resizeMobileHeight = function resizeMobileHeight() {
  var elem = this.container.find('.nav__container');

  // if open menu, set the container to the actual visible area (using window.innerHeight)
  if (this.container.hasClass(this.openMobileClass)) {

    var windowHeight = window.innerHeight || $(window).height(); // if can't get the true visible height minus toolbars, get the entire height as default
    /* istanbul ignore if */
    if (!windowHeight || windowHeight < 300) { // if there's a condition where the window height is too small, make min 300px
      windowHeight = 300;
    }

    var panelHeight = windowHeight - 50; // 50px for nav height mobile. Note: Want to use "this.panels.position().top", but sometimes returns the wrong location in iOS.

    elem.height(windowHeight);
    this.panels.css({height: panelHeight});
    this.panels.find('.nav-mobile').css({'min-height': panelHeight + 100}); // 100 to force some scrolling

  } else {
    // if closed menu, remove hardcoded height definition
    elem.css({height: ''});
    this.panels.css({height: ''});
    this.panels.find('.nav-mobile').css({'min-height': ''});
  }
};

Nav.prototype.handleMenuTabTrigger = function handleMenuTabTrigger(event) {
  // If mobile width, skip 'mouseenter' event. User needs to click to show menus.
  if (!this.showingBigMenu && event.type === 'mouseenter') {
    return;
  }

  event.preventDefault(); // prevent following href (href there for fallback if no JS)
  event.stopPropagation(); // don't bubble event onward (since another handler would close the menu)

  // if big menu and about to show the hover menu, wait it out
  // this also eats the extra event in touch devices where mouseeenter and click are triggered next to each other
  if (this.showingBigMenu && this.switchMenuTimeout) {
    return;
  }

  var tab = $(event.currentTarget).data('menu-tab');

  var trackingData = {
    eventName: 'Megamenu',
    TYPE: 'open',
    TARGET: tab || 'unknown',
    ACTION: 'hover'
  };

  // for big menu, if clicking open tab and past 800ms since you opened menu, then close it
  if (this.showingBigMenu && event.type === 'click' && tab === this.getOpenTabName() && (new Date().getTime()) > this.menuOpenTime + 800) {
    tab = null;
    trackingData.TYPE = 'close';
  }

  // if clicking, perform menu switch immediately
  if (event.type === 'click') {
    this.switchMenu(tab);
    trackingData.ACTION = 'click';
  } else {
    // if mouseenter (hover), show menu later.
    this.switchMenuTimeout = window.setTimeout($.proxy(this.switchMenu, this, tab), 250);
  }

  this.trackMegaMenu(trackingData);
};

// trackingData = {intent, action, tab}
Nav.prototype.trackMegaMenu = function trackMegaMenu(trackingData) {
  if (!window.lcTracking) {
    return;
  }

  window.lcTracking.trackCustomEvent(trackingData);
};

Nav.prototype.handleMouseLeave = function handleMouseLeave() {
  if (this.switchMenuTimeout) {
    window.clearTimeout(this.switchMenuTimeout);
    this.switchMenuTimeout = null;
  }
};

Nav.prototype.handleOpenTertiary = function handleOpenTertiary(event) {
  event.stopPropagation();
  event.preventDefault();

  // find associated tertiary menu with link and make it visible
  // need to remove scrollability to make tertiary panel appear
  var target = $(event.currentTarget).data('tertiary-menu-target');
  this.tertiaryPanels.filter('[data-tertiary-menu=' + target + ']').addClass(this.activeTertiaryPanelClass);
  this.mobilePanel.removeClass(this.mobilePanelScrollableClass);

  // Put this.openMobileTertiaryClass on container AND on document element as a helper
  // for "hybrid" site experience where header is brought into LCUI.
  this.container.addClass(this.openMobileTertiaryClass);
  $(document.documentElement).addClass(this.openMobileTertiaryClass);
};

Nav.prototype.handleCloseTertiary = function handleCloseTertiary(event) {
  event.stopPropagation();
  event.preventDefault();

  // Remove this.openMobileTertiaryClass from container AND from document element as a helper
  // for "hybrid" site experience where header is brought into LCUI.
  this.container.removeClass(this.openMobileTertiaryClass);
  $(document.documentElement).removeClass(this.openMobileTertiaryClass);

  // after 500ms ($med_transition_time) animation of closing, hide tertiary panels again and make mobile panel scrollable again
  window.setTimeout($.proxy(function() {
    this.tertiaryPanels.removeClass(this.activeTertiaryPanelClass);
    this.mobilePanel.addClass(this.mobilePanelScrollableClass);
    }, this),
    500);
};

/**
 * Open or close the mobile menu
 * @param  {String} action Action can be 'open' or 'close'
 */
Nav.prototype.handleMobileBtnClick = function handleMobileBtnClick(event) {
  event.stopPropagation();

  var openAction = !this.container.hasClass(this.openMobileClass);

  // Add or remove this.openMobileClass on container AND on document element as a helper
  // for "hybrid" site experience where header is brought into LCUI.
  this.container.toggleClass(this.openMobileClass, openAction);
  $(document.documentElement).toggleClass(this.openMobileClass, openAction);

  this.resizeMobileHeight();

  if (openAction && !this.hasOpenedMobile) {
    this.preNavigate();
    this.hasOpenedMobile = true;
  }
};

// open accordion and/or tertiary panel based on the navLevel1 and navLevel2
Nav.prototype.preNavigate = function preNavigate() {
  var navLevel1 = this.container.data('menu-current-level-1');
  var navLevel2 = this.container.data('menu-current-level-2');

  // if navLevel1 = 'home' then default to open BORROW accordion section
  if (navLevel1 === 'home') {
    navLevel1 = 'borrow';
  }

  // for level 1, open the accordion
  if (navLevel1) {
    var titleElem = this.container.find('[data-nav-level-1=' + navLevel1 + '] [data-accordion-title]').first();

    if (titleElem.length > 0) {
      var fakeEvent = {
        target: titleElem
      };
      this.accordion.toggle(fakeEvent);
    }
  }

  // for level 2, see if it opens a tertiary panel. if found, open it
  if (navLevel2) {
    var linkElem = this.container.find('[data-tertiary-open-link][data-nav-level-2=' + navLevel2 + ']').first();

    if (linkElem.length > 0) {
      // wait 500ms to account for accordion opening
      window.setTimeout(function() {
          linkElem.click();
        },
      500);
    }
  }
};

Nav.prototype.handleResize = function handleResize() {
  var width = window.innerWidth || $(window).width();
  this.showingBigMenu = width >= this.desktopWidth;

  // mobile --> desktop: if open mobile menu, return to closed mobile menu state (so X is hidden)
  if (this.lastWidth < this.desktopWidth && width >= this.desktopWidth && this.container.hasClass(this.openMobileClass)) {
     this.container.find('.nav__mobile-actions').trigger('click');
  }
  // desktop --> mobile: close any open menus, user needs to click hamburger
  if (this.lastWidth >= this.desktopWidth && width < this.desktopWidth) {
    this.switchMenu(null);
  }
  this.lastWidth = width;

  // if mobile menu, force resize of the 100vh element
  if (!this.showingBigMenu) {
    this.resizeMobileHeight();
  }

  // if scrollTop has changed, handleWindowScroll
  if ($(document).scrollTop() !== this.lastScrollTop) {
    this.handleWindowScroll();
  }
};

Nav.prototype.handleDocumentClick = function handleDocumentClick(event) {
  // for desktop, hide menu panel if panel is visible and user clicked outside of menu tab and menu panel
  if (this.showingBigMenu && this.getOpenTabName() && $(event.target).closest('[data-menu-tab],[data-menu-panel]').length === 0) {
    this.switchMenu(null);
  }
};

Nav.prototype.handleWindowScroll = function handleWindowScroll() {
  // if mobile menu, skip
  if (!this.showingBigMenu) {
    return;
  }

  this.setCtaVisibility();

  // if desktop and there's an open menu tab, close all menus
  if (this.getOpenTabName()) {
    this.switchMenu(null);
  }

  // if secondary menu is on page and is visible (since it may be hidden for mobile breakpoint), dock/undock/merge main menus
  if (this.secondaryNav && this.secondaryNav.is(':visible')) {
    this.dockMergeSecondaryNav();
  }

};

Nav.prototype.dockMergeSecondaryNav = function dockMergeSecondaryNav() {
  var navFadeClass = 'nav--fadeout';
  var navShiftOffscreenClass = 'nav--shift-offscreen'; // to shift nav upward when redocking secondary nav
  var secondaryTopDockClass = 'secondary-nav--docked'; // docked to top of window
  var secondaryUnderMainClass = 'secondary-nav--docked-below-main'; // to shift down a docked secondary nav underneath main menu
  var secondaryOverlapClass = 'secondary-nav--overlap';

  var navHeight = this.container.height();

  var scrollTop = $(document).scrollTop();
  var secondaryScrollTop = this.secondaryNav.offset().top - scrollTop;

  var isSecondaryTopDocked = this.secondaryNav.hasClass(secondaryTopDockClass);
  var isSecondaryUnderMain = this.secondaryNav.hasClass(secondaryUnderMainClass);

  // SCROLLING UP
  if (scrollTop > this.lastScrollTop) {

    // if secondary is docked under main menu, redock secondary nav to top and move main menu up offscreen
    if (isSecondaryUnderMain) {

      this.secondaryNav.addClass(secondaryTopDockClass).removeClass(secondaryUnderMainClass);
      this.container.addClass(navShiftOffscreenClass);

    } else if (!isSecondaryTopDocked) {
      // merging of main menu and secondary nav while scrolling up

      // if overlapping secondary nav and nav, then make more translucent
      if (secondaryScrollTop < navHeight && !this.secondaryNav.hasClass(secondaryOverlapClass)) {
        this.secondaryNav.addClass(secondaryOverlapClass);
      }

      // if secondary nav hit top of screen, dock secondary nav, hide main menu
      if (secondaryScrollTop <= 0) {
        this.container.addClass(navFadeClass);
        this.secondaryNav.removeClass(secondaryOverlapClass).addClass(secondaryTopDockClass);
        this.container.addClass(navShiftOffscreenClass);
      }
    }
  }

  // SCROLLING DOWN
  if (scrollTop < this.lastScrollTop) {

    // if secondary is top docked, then show main menu and move secondary nav underneath main menu
    if (isSecondaryTopDocked) {

      this.container.removeClass(navFadeClass + ' ' + navShiftOffscreenClass);
      this.secondaryNav.removeClass(secondaryTopDockClass).addClass(secondaryUnderMainClass);

    } else if (isSecondaryUnderMain) {

      // if original secondary nav location scrolled below bottom of main menu, then undock secondary nav
      if (secondaryScrollTop > navHeight) {
        this.secondaryNav.removeClass(secondaryUnderMainClass);
      }
    }
  }

  this.lastScrollTop = scrollTop;
};

Nav.prototype.addListeners = function addListeners() {
  // menu elements
  this.container.on('click mouseenter', '[data-menu-tab]', $.proxy(this.handleMenuTabTrigger, this)); // mouseenter is for hover
  this.container.on('mouseleave', '[data-menu-tab]', $.proxy(this.handleMouseLeave, this)); // mouseenter is for hover
  this.container.on('click touchstart', '[data-tertiary-open-link]', $.proxy(this.handleOpenTertiary, this));
  this.container.on('click touchstart', '[data-tertiary-close-link]', $.proxy(this.handleCloseTertiary, this));
  this.container.on('click', '.nav__mobile-actions', $.proxy(this.handleMobileBtnClick, this));

  // window resize
  var resizeFunction = debounce($.proxy(this.handleResize, this), 200);
  $(window).on('resize', resizeFunction);

  // clicking outside of menu tab and panel closes hover
  $(document).on('click touchstart', $.proxy(this.handleDocumentClick, this));

  // scroll of window
  $(window).on('scroll touchmove', $.proxy(this.handleWindowScroll, this));
};

// instantiate the main menu
var secondaryNav = $('[data-secondary-nav]');
var ctaScrollPoint = $('[data-show-nav-cta-scroll-point]');
new Nav($('[data-site-header]'), secondaryNav.length > 0 ? secondaryNav : null, ctaScrollPoint.length > 0 ? ctaScrollPoint : null);

module.exports = Nav; // for testing

require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/xd-site-header/src/images/chevron-red.svg');
require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/xd-site-header/src/images/chevron.svg');
require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/xd-site-header/src/images/lending-club-logo-white.svg');
require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/xd-site-header/src/images/lending-club-logo.svg');
require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/xd-site-header/src/images/nav-close.svg');
require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/xd-site-header/src/images/nav-open.svg');