/* eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Grid } from '@ui-propulsion/react-components';
import HeaderLogo from '../header-logo';


const SecondaryNavBand = ({ tabs, visibleLogo }) => {
  const subMenuHeapTrack = async(analytics_key) => {};
  const tabsLength = tabs.length;
  const tabsDifferentThanFive = tabsLength !== 5;

  const classNamesItemNarrowContent = (active) => classNames({
    'GlobalSecondaryNavBand__tab-list-item-content': tabsDifferentThanFive,
    'GlobalSecondaryNavBand__tab-list-item-narrow': !tabsDifferentThanFive,
    'GlobalSecondaryNavBand__tab-list-item-content--active': tabsDifferentThanFive && active,
    'GlobalSecondaryNavBand__tab-list-item-narrow--active': !tabsDifferentThanFive && active
  });

  const renderSecondaryTabs = () => {
    return (
      <ul className="GlobalSecondaryNavBand__tab-list">
        {tabs.map((item, index) => {
          const analytics_key = item.text.toLowerCase().split(' ').join('_');
          return (
            <li key={index} className="GlobalSecondaryNavBand__tab-list-item">
              <a
                id={`list-item-a-${index}`}
                href={item.url}
                className={classNamesItemNarrowContent(item.active)}
                onClick={() => subMenuHeapTrack(analytics_key)}
              >
              {item.text}
              </a>
            </li>
          );
        })}
      </ul>
    );
  }
  
  return (
    <header className="GlobalSecondaryNavBand">
        <div className="GlobalSecondaryNavBand__wrapper">
            <Grid container={true} direction='row'>
              <Grid item={true} sm={6} lg={6}>
              <HeaderLogo className={classNames('GlobalSecondaryNavBand__logo', {'GlobalSecondaryNavBand__logo--docked': visibleLogo})} theme="blue" />
                </Grid>
              <Grid item={true} sm={6} lg={6}>
          <nav aria-label="GlobalSecondary Nav" role="navigation" className="GlobalSecondaryNavBand__container">
            <div className="GlobalSecondaryNavBand__nav">
              {renderSecondaryTabs()}
            </div>
          </nav>
                </Grid>
                </Grid>
        </div>
    </header>
  );
};

SecondaryNavBand.propTypes = {
  tabs: PropTypes.array,
  visibleLogo: PropTypes.bool
};

export default SecondaryNavBand;

require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/components/global-header/components/secondary-nav-band-wsn/secondary-nav-band-wsn.scss');