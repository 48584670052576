import React from 'react';

import PropTypes from 'prop-types';

/* eslint-disable max-lines-per-function */
const SignIn = ({ isLoggedIn }) => {

  /* eslint-disable space-before-function-paren */
  const signInHeapTrack = async (analytics_key) => { };

  let content;
  if (isLoggedIn) {
    content = (
      <ul className="nav-list">
        <li className="sign_btn"><a href="/account/logout.action" onClick={() => signInHeapTrack('sign_out')}>Sign Out</a></li>
      </ul>
    );
  } else {
    return <span></span>;
  }
  return content;
};

SignIn.propTypes = {
  isLoggedIn: PropTypes.bool
};

export default SignIn;

require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/shared_modules/components/global-header/components/sign-in/sign-in.scss');