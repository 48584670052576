import React from 'react';
import PropTypes from 'prop-types';
import randomID from '../../../../utils/createRandomId';
import TableCell from '../table-cell/table-cell';

const TableRow = ({ rowData, isHeading }) => {
  const getCells = () => {
    return rowData.map((content, cellIndex) => {
      return (
        <TableCell key={`cell-${randomID('cell')}-${cellIndex}`} header={isHeading}>
          {content}
        </TableCell>
      );
    });
  };

  return <tr className="DataTable__row" key={`${randomID('row')}`}>{getCells()}</tr>;
};

TableRow.defaultProps = {
  isHeading: false
};

TableRow.propTypes = {
  rowData: PropTypes.array,
  isHeading: PropTypes.bool
};

export default TableRow;

